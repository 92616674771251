<mp-autocomplete-input
  [(selectedValue)]="selectedValue"
  [autocompletePanel]="panel"
  [autocompleteInputMode]="autocompleteInputMode()"
  [appearance]="appearance()"
  [inline]="inline()"
  [required]="required()"
  [disabled]="disabled()"
  [clearable]="clearable()"
  [autocompleteIcon]="autocompleteIcon()"
  [label]="label()"
  [labelTemplate]="labelTemplate()"
  [placeholder]="placeholder()"
  [errorMessage]="errorMessage()"
  [chipItemTemplate]="chipItemTemplate()"
  (searchTermChange)="searchTermChange.emit($event)"
  (clear)="clear.emit()"
/>

<mp-autocomplete-panel
  #panel
  [options]="options()"
  [optionTemplate]="optionTemplate()"
  [emptyOptionsListTemplate]="emptyOptionsListTemplate()"
/>
