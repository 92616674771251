import type Quill from 'quill';
import type BaseTheme from 'quill/themes/base';
import { type BaseTooltip } from 'quill/themes/base';

export function richTextEditorImageHandler(quillEditor: Quill): void {
  const theme: BaseTheme = quillEditor.theme as BaseTheme;
  const tooltip: BaseTooltip | undefined = theme.tooltip as BaseTooltip | undefined;

  if (!tooltip) {
    return;
  }

  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const { index } = quillEditor.getSelection(true);
    const value: string | undefined = this.textbox?.value;
    if (value) {
      quillEditor.insertEmbed(index, 'image', value, 'user');
    }
  };

  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };

  tooltip.edit('image');

  if (tooltip.textbox) {
    tooltip.textbox.placeholder = 'Image-URL';
  }
}
