<mat-card class="panel-card">
  <mat-expansion-panel
    class="panel"
    [hideToggle]="true"
    [expanded]="expanded()"
    (expandedChange)="setPanelExpanded($event)"
  >
    <mat-expansion-panel-header class="panel__header">
      @if (panelHeaderTemplate(); as panelHeaderTemplate) {
        <ng-template [ngTemplateOutlet]="panelHeaderTemplate" />
      } @else {
        <h3 class="panel__header-text truncate" [title]="panelTitle()">
          {{ panelTitle() }}
        </h3>
      }
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <ng-template [ngTemplateOutlet]="expansionTemplate" />
    </ng-template>
  </mat-expansion-panel>

  <div class="panel-card__expansion-actions" (click)="togglePanelExpanded()">
    <mp-expand-button [isPanelExpanded]="expanded()" [expandButtonLabel]="expandButtonLabel()" />
  </div>
</mat-card>
