import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-select-all-flyout-elements-button',
  standalone: true,
  templateUrl: './select-all-flyout-elements-button.component.html',
  styleUrls: ['./select-all-flyout-elements-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButtonModule, MatIconModule],
})
export class SelectAllFlyoutElementsButtonComponent {
  @HostBinding('class') readonly class = 'mp-select-all-flyout-elements-button';

  @Input() isAllItemsSelected = false;

  @Input() isNoItemsSelected = false;

  @Output() readonly selectAllClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly deselectAllClick: EventEmitter<void> = new EventEmitter<void>();

  selectAll(): void {
    this.selectAllClick.emit();
  }

  deselectAll(): void {
    this.deselectAllClick.emit();
  }
}
