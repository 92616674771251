import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AlertIconDirective, AlertTitleDirective } from './alert.directive';

export type AlertType = 'info' | 'warning' | 'danger';

@Component({
  selector: 'mp-alert',
  standalone: true,
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconModule, AlertTitleDirective, AlertIconDirective],
})
export class AlertComponent {
  @HostBinding('class') class = 'mp-alert';

  @Input() type: AlertType = 'danger';
}
