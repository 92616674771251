<ng-container *ngIf="inputFieldVisible; then inlineEditInput; else content" />

<ng-template #content>
  <ng-content />
</ng-template>

<ng-template #inlineEditInput>
  <div class="inline-form">
    <mat-form-field>
      <input
        #inlineInput
        matInput
        #inputValue
        [value]="value"
        [placeholder]="inputPlaceholder"
        [attr.maxlength]="maxLength"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (keydown.enter)="onValueSubmit(inputValue.value)"
      />
    </mat-form-field>
    <button
      mat-icon-button
      class="inline-form__confirm-btn"
      [disabled]="inputValue.value.length < minLength"
      (click)="onValueSubmit(inputValue.value); $event.stopPropagation()"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button (click)="cancelEdit(); $event.stopPropagation()" class="inline-form__reject-btn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>
