<div class="rich-text-editor" *ngrxLet="editorLoaded$ as editorLoaded">
  <div
    quill-editor-toolbar
    mp-rich-text-editor-toolbar
    [ngClass]="{ 'rich-text-editor__toolbar--hidden': !editorLoaded }"
  ></div>

  <div quill-editor-element></div>

  <mp-spinner *ngIf="!editorLoaded"></mp-spinner>
</div>
