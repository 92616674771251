<mat-form-field>
  <input matInput type="text" [placeholder]="placeholder" [(ngModel)]="inputValue" />
</mat-form-field>
<button
  mat-raised-button
  color="accent"
  class="save-button"
  [disabled]="disabled"
  [matTooltip]="disabled ? disabledStateTooltipText : ''"
  (click)="emitSave()"
>
  Speichern
</button>
