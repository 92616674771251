import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

import { SelectOption } from '../../../../option';

@Component({
  selector: 'mp-selector-element',
  standalone: true,
  templateUrl: './selector-element.component.html',
  styleUrl: './selector-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyCheckboxModule],
})
export class SelectorElementComponent<T> {
  @HostBinding() readonly class = 'mp-selector-element';

  readonly element: InputSignal<SelectOption<T>> = input.required<SelectOption<T>>();

  readonly isSelected: InputSignal<boolean> = input.required<boolean>();

  readonly valueSelect: OutputEmitterRef<T> = output<T>();

  readonly valueDeselect: OutputEmitterRef<T> = output<T>();

  selectValue(value: T): void {
    this.valueSelect.emit(value);
  }

  deselectValue(value: T): void {
    this.valueDeselect.emit(value);
  }
}
