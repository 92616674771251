<ul class="agreement-info-list">
  @if (agreementBaseInfo().baseAmount; as baseAmount) {
    <li class="agreement-info-list__row">
      <mp-param-wrapper
        class="mp-param-wrapper--horizontal agreement-info-list__row-param"
        [label]="agreementBaseInfo().description + ':'"
        [boldLabel]="true"
      >
        <span class="agreement-info-list__value font-bold">
          <span class="agreement-info-list__value-prefix">Ab</span>
          {{ baseAmount | mpGraduationValueOutput }}
        </span>
      </mp-param-wrapper>
    </li>
  }

  @if (agreementBaseInfo().validFrom; as validFrom) {
    <li class="agreement-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal agreement-info-list__row-param" label="Gültig von:">
        <span class="font-bold">{{ validFrom | date: 'dd.MM.yyyy' }}</span>
      </mp-param-wrapper>
    </li>
  }

  @if (agreementBaseInfo().validUntil; as validUntil) {
    <li class="agreement-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal agreement-info-list__row-param" label="Gültig bis:">
        <span class="font-bold">{{ validUntil | date: 'dd.MM.yyyy' }}</span>
      </mp-param-wrapper>
    </li>
  }
</ul>
