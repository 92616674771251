import { InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';

/**
 * Abstract signature of the service for tracking insights events.
 * Used to provide possibly data-access related service by abstraction.
 * Example usage: providers: [{provide: InsightsEventsTrackingService, useExisting: AppInsightsFacade}]
 */
export abstract class InsightsEventsTrackingService {
  abstract trackEvent(event: InsightsEvent, customProperties?: InsightsEventProperties): void;
}
