import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mp-entity-item-slots',
  standalone: true,
  templateUrl: './entity-item-slots.component.html',
  styleUrl: './entity-item-slots.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemSlotsComponent {
  @HostBinding('class') readonly class = 'mp-entity-item-slots';
}
