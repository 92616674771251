import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { pairwise, startWith } from 'rxjs/operators';

type IndexChangeEvent = {
  previousIndex: number;
  currentIndex: number;
};

@Component({
  selector: 'mp-drag-handle',
  standalone: true,
  templateUrl: './drag-handle.component.html',
  styleUrl: './drag-handle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule],
})
export class DragHandleComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-drag-handle';

  @Input()
  get invertArrowKeys() {
    return this._invertArrowKeys;
  }

  set invertArrowKeys(value: BooleanInput) {
    this._invertArrowKeys = coerceBooleanProperty(value);
  }

  private _invertArrowKeys = false;

  @Input()
  get showIndexInput() {
    return this._showIndexInput;
  }

  set showIndexInput(value: BooleanInput) {
    this._showIndexInput = coerceBooleanProperty(value);
  }

  private _showIndexInput = false;

  @Input() set index(index: number) {
    this.indexInputControl.setValue(index, { emitEvent: false });
  }

  @Input() minIndex = 1;
  @Input() maxIndex?: number;

  @Output() readonly indexChange = new EventEmitter<IndexChangeEvent>();

  readonly indexInputControl: UntypedFormControl = new UntypedFormControl('', { updateOn: 'submit' });

  constructor(private readonly destroyRef: DestroyRef) {}

  handleArrowKey(key: 'up' | 'down'): void {
    const previousIndex = this.indexInputControl.value;
    const direction = (key === 'up' ? -1 : 1) * (this.invertArrowKeys ? -1 : 1);
    const currentIndex = previousIndex + direction;

    this.indexChange.emit({ previousIndex, currentIndex });
  }

  ngOnInit(): void {
    // NOTE: This subscription is done inside ngOnInit,
    // since we need to wait for the "indexInputControl" to hold a value.
    this.indexInputControl.valueChanges
      .pipe(startWith(this.indexInputControl.value), pairwise(), takeUntilDestroyed(this.destroyRef))
      .subscribe({ next: ([previousIndex, currentIndex]) => this.indexChange.emit({ previousIndex, currentIndex }) });
  }
}
