import { ChangeDetectionStrategy, Component, InputSignal, ModelSignal, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-graduation-expand-button',
  standalone: true,
  templateUrl: './graduation-expand-button.component.html',
  styleUrl: './graduation-expand-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class GraduationExpandButtonComponent {
  readonly isExpanded: ModelSignal<boolean> = model.required<boolean>();

  readonly hiddenElementsCount: InputSignal<number> = input.required<number>();

  toggleExpanded(): void {
    this.isExpanded.set(!this.isExpanded());
  }
}
