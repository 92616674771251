import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective } from '@ngrx/component';

import { GraduationValueType } from '@core/shared/domain';

import { GraduationLevel, GraduationValue } from '../../models';
import { GraduationValueOutputPipe } from '../../pipes';

import { PriceValidityDatesPipe } from './price-validity-dates.pipe';

@Component({
  selector: 'mp-graduation-effect',
  standalone: true,
  templateUrl: './graduation-effect.component.html',
  styleUrl: './graduation-effect.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, LetDirective, MatIconModule, MatTooltipModule, GraduationValueOutputPipe, PriceValidityDatesPipe],
})
export class GraduationEffectComponent {
  @HostBinding() readonly class = 'mp-graduation-effect';

  readonly graduationLevel: InputSignal<GraduationLevel> = input.required<GraduationLevel>();

  readonly isFutureCondition: InputSignal<boolean> = input<boolean>(false);

  readonly showValidityDatesTooltip: InputSignal<boolean> = input<boolean>(false);

  readonly hasPriceValidityDates: Signal<boolean> = computed(() => {
    if (!this.showValidityDatesTooltip()) {
      return false;
    }

    const graduationEffect: GraduationValue | undefined = this.graduationLevel().effect;

    if (graduationEffect?.type === GraduationValueType.Price) {
      const { validFrom, validUntil } = graduationEffect;

      return !!validFrom || !!validUntil;
    }

    return false;
  });
}
