import { NgModule } from '@angular/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';

import { ConfirmDialogComponent } from './confirm-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatLegacyDialogConfig = {
  panelClass: 'mp-confirm-dialog-container',
};

@NgModule({
  imports: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent],
  providers: [{ provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class ConfirmDialogModule {}
