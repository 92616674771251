import { NgModule } from '@angular/core';

import { ViewColumnDirective } from './column.directive';
import { ViewComponent } from './view.component';

@NgModule({
  imports: [ViewColumnDirective, ViewComponent],
  exports: [ViewColumnDirective, ViewComponent],
})
export class ViewModule {}
