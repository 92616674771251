@if (isCustomHeader) {
  <ng-container *mpHeaderElement="'right'">
    <ng-container *ngTemplateOutlet="submitFormButton" />
  </ng-container>
} @else {
  <div class="title-bar">
    <button mat-icon-button color="accent" aria-label="Close Form" (click)="cancel.next()">
      <mat-icon>close</mat-icon>
    </button>

    <div class="title">
      {{ viewTitle }}
    </div>

    <ng-container *ngTemplateOutlet="submitFormButton" />
  </div>
}

<ng-template #submitFormButton>
  @if (!readonly) {
    <button
      mat-fab
      class="mp-view-action-fab"
      [ngClass]="{ 'mp-view-action-fab--custom': isCustomHeader }"
      aria-label="Submit Form"
      [disabled]="!submittable"
      (click)="send.next()"
    >
      <mat-icon>check</mat-icon>
    </button>
  }
</ng-template>

<div class="detail-page">
  <div class="detail-page-content">
    <ng-content />
  </div>
</div>
<div class="detail-page-context-bar" [@openClose]="isContextBarActive ? 'open' : 'closed'">
  <router-outlet name="contextbar" (activate)="isContextBarActive = true" (deactivate)="isContextBarActive = false" />
</div>
