import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-navigate-back-button',
  standalone: true,
  templateUrl: './navigate-back-button.component.html',
  styleUrls: ['./navigate-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatLegacyButtonModule],
})
export class NavigateBackButtonComponent {
  @Output() readonly navigateBackClick: EventEmitter<void> = new EventEmitter<void>();

  navigateBack(): void {
    this.navigateBackClick.emit();
  }
}
