<div class="col-profile">
  <a routerLink="/profile" class="profile-link">
    <ng-content />
  </a>
</div>

<div class="col-profile-actions-button">
  <button mat-icon-button [matMenuTriggerFor]="profileActionsMenu">
    <mat-icon>manage_accounts</mat-icon>
  </button>
</div>

<mat-menu #profileActionsMenu="matMenu">
  <ng-content select="[mat-menu-item]" />

  <button mat-menu-item (click)="handleLogout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Abmelden</span>
  </button>
</mat-menu>
