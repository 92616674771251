import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ChipItemSelectorComponent, ItemSelectorOption } from '../chip-item-selector/chip-item-selector.component';
import { ValueElementDirective } from '../core/value-element';

type EnumType<TEnum> = {
  [K in keyof TEnum]: number;
};

@Component({
  selector: 'mp-enum-selector',
  standalone: true,
  templateUrl: './enum-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChipItemSelectorComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumSelectorComponent),
      multi: true,
    },
  ],
})
export class EnumSelectorComponent<TEnum extends EnumType<TEnum>> extends ValueElementDirective<number> {
  @Input() set enumType(value: TEnum) {
    this.convertEnumTypeToSelectorOptions(value);
  }

  @Input() label = '';

  protected enumSelectorOptions: ItemSelectorOption<number>[] = [];

  protected selectedValues: number[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
    this.class = 'mp-enum-selector';
  }

  override writeValue(value: number) {
    super.writeValue(value);

    this.selectedValues = this.extractSelectedValuesFromBinaryValue(this.value);

    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  private extractSelectedValuesFromBinaryValue(summaryBinaryValue: number | undefined): number[] {
    if (!summaryBinaryValue) {
      return [];
    }

    return this.enumSelectorOptions
      .filter((item) => this.isItemSelected(item, summaryBinaryValue))
      .map(({ value }) => value);
  }

  private isItemSelected({ value }: ItemSelectorOption<number>, summaryBinaryValue: number): boolean {
    // The check is done by bitwise AND operation which returns non-zero value if both operands have 1 at the same bit position.
    return value === (summaryBinaryValue & value);
  }

  private convertEnumTypeToSelectorOptions(enumType: TEnum): void {
    this.enumSelectorOptions = Object.entries(enumType)
      .filter(([key, value]) => typeof key === 'string' && typeof value === 'number' && value > 0)
      .map(([key, value]) => ({ label: key, value: value as number }));
  }

  onSelectedValuesChange(selectedValues: number[]): void {
    this.selectedValues = selectedValues;
    this.updateSummaryBinaryValue();
  }

  private updateSummaryBinaryValue(): void {
    let summaryBinaryValue = 0;
    this.selectedValues.forEach((value) => (summaryBinaryValue |= value));

    this.value = summaryBinaryValue;
  }
}
