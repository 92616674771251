<div class="list-header">
  <div class="list-header__prefix">
    <ng-content select="[list-header-prefix]" />
  </div>

  <div class="list-header__content list-header-content truncate">
    @if (showListHeaderSearchLabel() && headerValue()) {
      <span class="list-header-content__label" [title]="listHeaderSearchLabel()">
        {{ listHeaderSearchLabel() }}
      </span>
    }

    <h2
      class="list-header-content__title truncate"
      *ngrxLet="headerValue() || headerPlaceholder() as headerText"
      [title]="headerText"
    >
      <strong>{{ headerText }}</strong>
    </h2>

    <ng-content select="[list-header-subtitle]" />

    <div class="list-header-result-row">
      <mp-list-results-info
      class="truncate"
      [totalItemsQuantity]="totalItemsQuantity()"
      [selectedItemsQuantity]="selectedItemsQuantity()"
      />

      <div class="list-header-result-row__additional">
        <ng-content select="[list-header-result-row-additional]" />
      </div>
    </div>
  </div>
</div>

<div class="list-header-context">
  <div class="list-header-context__actions">
    <ng-content />
  </div>
  <div class="list-header-context__additional">
    <ng-content select="[list-header-additional-context]" />
  </div>
</div>
