import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChip as MatChip, MatLegacyChipsModule } from '@angular/material/legacy-chips';

import { AvatarComponent } from '../../avatar/avatar.component';
import { EntityIcons } from '../entity-icons';
import { EntityType } from '../entity-type';

@Component({
  selector: 'mp-entity-item',
  standalone: true,
  templateUrl: './entity-item.component.html',
  styleUrl: './entity-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatLegacyChipsModule, MatIconModule, AvatarComponent],
})
export class EntityItemComponent {
  @HostBinding('class') readonly class = 'mp-entity-item';
  @HostBinding('class.item--removed') removed = false;

  @ContentChildren(MatChip) chips!: QueryList<MatChip>;

  @Input() @HostBinding('class.item--hidden') hidden = false;

  @Input() id?: string;
  @Input() label?: string;
  @Input() meta?: string;

  @Input() icon?: string;

  /**
   * Convenience property that sets the components {@link icon} based on a use-case.
   *
   * All this does is basically lookup the required material icon from the {@link EntityIcons} map.
   * */
  @Input() set type(entityType: EntityType) {
    if (!this.icon && entityType) {
      this.icon = EntityIcons[entityType];
    }
  }

  @Input()
  get editMode() {
    return this._editMode;
  }
  set editMode(value: BooleanInput) {
    this._editMode = coerceBooleanProperty(value);
  }
  private _editMode = false;

  @Output() readonly remove = new EventEmitter<string>();
  @Output() readonly undo = new EventEmitter<string>();

  removeClicked() {
    this.removed = true;
    this.remove.emit(this.id);
  }

  undoClicked() {
    this.removed = false;
    this.undo.emit(this.id);
  }
}
