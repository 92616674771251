import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mp-entity-item-icon',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemIconSlotComponent {}

@Component({
  selector: 'mp-entity-item-content',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemContentSlotComponent {}

@Component({
  selector: 'mp-entity-item-content-label',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemContentLabelSlotComponent {}

@Component({
  selector: 'mp-entity-item-content-meta',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemContentMetaSlotComponent {}

@Component({
  selector: 'mp-entity-item-actions',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemActionsSlotComponent {}

@Component({
  selector: 'mp-entity-item-footer',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemFooterSlotComponent {}
