import { ChangeDetectionStrategy, Component, InputSignal, ModelSignal, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'mp-image-gallery-viewer-controller',
  standalone: true,
  templateUrl: './image-gallery-viewer-controller.component.html',
  styleUrl: './image-gallery-viewer-controller.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatLegacyCheckboxModule],
})
export class ImageGalleryViewerControllerComponent {
  readonly selectedImageIndex: ModelSignal<number> = model<number>(0);

  readonly showImagesInOriginalSize: ModelSignal<boolean> = model<boolean>(false);

  readonly imagesNumber: InputSignal<number> = input<number>(0);

  readonly showOriginalSizeCheckbox: InputSignal<boolean> = input<boolean>(false);

  selectPreviousImageIndex(): void {
    if (this.selectedImageIndex() !== 0) {
      this.selectedImageIndex.set(this.selectedImageIndex() - 1);
    }
  }

  selectNextImageIndex(): void {
    if (this.selectedImageIndex() < this.imagesNumber() - 1) {
      this.selectedImageIndex.set(this.selectedImageIndex() + 1);
    }
  }

  toggleShowImagesInOriginalSize(): void {
    this.showImagesInOriginalSize.set(!this.showImagesInOriginalSize());
  }
}
