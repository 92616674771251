<span class="chip truncate" [class]="{ 'chip--small': small(), 'chip--removable': removable() }">
  @if (chip().name) {
    <span class="chip__name truncate" [title]="chip().name">
      {{ chip().name }}
    </span>
  }
  <span class="chip__label truncate" [title]="chip().label">
    {{ chip().label }}
  </span>

  @if (removable()) {
    <button mat-icon-button class="chip__button" (click)="removeChip(chip().value)">
      <mat-icon class="chip__button-icon">close</mat-icon>
    </button>
  }
</span>
