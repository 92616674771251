import { InputSignal, ModelSignal } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
// This is one of the few places where we allow this import
// eslint-disable-next-line no-restricted-imports
import { MatLegacySelect } from '@angular/material/legacy-select';
import { Observable } from 'rxjs';

/**
 * Special class which implements the MatFormFieldControl in order to use a custom select component inside a form field.
 * It connects the custom component with properties of the mat-select control underneath.
 *
 * The ignore comments are quire unfortunate, but it is the way which material recommends as they don't use signals yet.
 *
 * Full guide can be found in Angular Material documentation for legacy controls:
 * https://v14.material.angular.io/guide/creating-a-custom-form-field-control.
 */
export abstract class FormFieldCustomSelectControlComponent<T> extends MatLegacyFormFieldControl<T> {
  static nextId = 0;

  abstract readonly selectedValue: ModelSignal<T | undefined>;

  abstract readonly isRequired: InputSignal<boolean>;

  abstract readonly isDisabled: InputSignal<boolean>;

  abstract readonly inputPlaceholder: InputSignal<string | undefined>;

  protected abstract readonly selectControl: MatLegacySelect;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line rxjs/finnish
  override get stateChanges(): Observable<void> {
    return this.selectControl.stateChanges;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  get value(): T | null {
    return this.selectedValue() ?? null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get placeholder(): string {
    return this.inputPlaceholder() ?? '';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get required(): boolean {
    return this.isRequired();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get disabled(): boolean {
    return this.isDisabled();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get focused(): boolean {
    return this.selectControl.focused;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get empty(): boolean {
    return this.selectControl.empty;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get shouldLabelFloat(): boolean {
    return this.selectControl.shouldLabelFloat;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override get errorState(): boolean {
    return this.selectControl.errorState;
  }

  protected constructor(public override ngControl: NgControl) {
    super();
  }

  onContainerClick(): void {
    this.selectControl.onContainerClick();
  }

  setDescribedByIds(ids: string[]): void {
    this.selectControl.setDescribedByIds(ids);
  }
}
