import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  TemplateRef,
  input,
  model,
  output,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { SelectOption } from '../option';

import {
  AutocompleteInputAppearance,
  AutocompleteInputComponent,
  AutocompleteInputMode,
  ChipItemTemplateContext,
  ChipItemTemplateRef,
} from './autocomplete-input/autocomplete-input.component';
import {
  AutocompleteOptionTemplateContext,
  AutocompleteOptionTemplateRef,
  AutocompletePanelComponent,
} from './autocomplete-panel/autocomplete-panel.component';

@Component({
  selector: 'mp-autocomplete',
  standalone: true,
  templateUrl: './autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutocompleteInputComponent, AutocompletePanelComponent],
})
export class AutocompleteComponent<T> implements ControlValueAccessor {
  @HostBinding() readonly class = 'mp-autocomplete';

  readonly selectedValue: ModelSignal<T | undefined> = model<T | undefined>();
  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly autocompleteInputMode: InputSignal<AutocompleteInputMode> = input<AutocompleteInputMode>('input');
  readonly appearance: InputSignal<AutocompleteInputAppearance> = input<AutocompleteInputAppearance>('standard');

  readonly inline: InputSignal<boolean> = input<boolean>(false);

  readonly disabled: InputSignal<boolean> = input<boolean>(false);
  readonly required: InputSignal<boolean> = input<boolean>(false);
  readonly clearable: InputSignal<boolean> = input<boolean>(false);

  readonly autocompleteIcon: InputSignal<string | undefined> = input<string>();

  readonly label: InputSignal<string | undefined> = input<string>();
  readonly labelTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();
  readonly placeholder: InputSignal<string | undefined> = input<string>();

  readonly errorMessage: InputSignal<string | undefined> = input<string>();

  readonly optionTemplate: InputSignal<AutocompleteOptionTemplateRef<T> | undefined> =
    input<AutocompleteOptionTemplateRef<T>>();
  readonly emptyOptionsListTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();
  readonly chipItemTemplate: InputSignal<ChipItemTemplateRef<T> | undefined> = input<ChipItemTemplateRef<T>>();

  readonly searchTermChange: OutputEmitterRef<string> = output<string>();

  readonly clear: OutputEmitterRef<void> = output<void>();

  readonly optionTemplateContextType!: AutocompleteOptionTemplateContext<T>;

  readonly chipItemTemplateContextType!: ChipItemTemplateContext<T>;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChange = (_value: T | undefined) => {};

  onTouched = () => {};

  writeValue(value: T | undefined): void {
    this.selectedValue.set(value);
    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: T | undefined) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
}
