import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'mp-search-field',
  standalone: true,
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule],
})
export class SearchFieldComponent {
  @HostBinding('class') class = 'mp-search-field';

  @Input() placeholder = 'Durchsuchen';

  @Input()
  @HostBinding('class.mp-search-field--disabled')
  get disabled(): BooleanInput {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.searchField[this._disabled ? 'disable' : 'enable']();
  }

  private _disabled = false;

  @Input()
  get value(): string {
    return this.searchField.value;
  }

  set value(newValue: string) {
    if (newValue == null) return;
    if (this.value === newValue) return;
    this.searchField.setValue(newValue, { emitEvent: false });
  }

  @Input()
  set displayValue(newValue: string) {
    this.searchField.setValue(newValue, { emitEvent: false });
  }

  @Output() readonly cleared = new EventEmitter<void>();
  @Output() readonly searched = new EventEmitter<string>();
  @Output() readonly searchTerm = new EventEmitter<string>();
  @Output() readonly valueChange = new EventEmitter<string>();

  readonly searchField = new UntypedFormControl('');

  constructor() {
    this.setupValueChangeEmitter();
    this.setupSearchTermEmitter();
    this.setupClearedEmitter();
  }

  private setupValueChangeEmitter(): void {
    this.searchField.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe({ next: (value) => this.valueChange.next(value) });
  }

  private setupSearchTermEmitter(): void {
    this.searchField.valueChanges
      .pipe(
        debounceTime(500),
        map((searchTerm: string) => searchTerm.trim()),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe({ next: (searchTerm) => this.searchTerm.next(searchTerm) });
  }

  private setupClearedEmitter(): void {
    this.searchTerm
      .pipe(
        filter((searchTerm) => searchTerm.length === 0),
        takeUntilDestroyed(),
      )
      .subscribe({ next: () => this.cleared.next() });
  }

  search(searchTerm: string): void {
    this.value = searchTerm;
    this.emitSearchEvent();
  }

  clear(): void {
    this.value = '';
  }

  emitSearchEvent(): void {
    this.searched.emit(this.searchField.value.trim());
  }
}
