import { NgModule } from '@angular/core';

import { NavigationItemComponent } from './navigation-item.component';
import { NavigationProfileComponent } from './navigation-profile.component';

@NgModule({
  imports: [NavigationItemComponent, NavigationProfileComponent],
  exports: [NavigationItemComponent, NavigationProfileComponent],
})
export class NavigationModule {}
