<div class="title-bar">
  <button mat-icon-button aria-label="Close Flyout" color="accent" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="title">
    {{ title() }}
  </div>

  <div class="action-fab">
    <ng-content select="[header-action-fab]" />
  </div>
</div>
<div class="content">
  <ng-content />

  <div class="content-action-fab">
    <ng-content select="[content-action-fab]" />
  </div>
</div>
