import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';

export interface TabLink {
  link: string;
  label: string;
}

@Component({
  selector: 'mp-navigation-tabs-menu',
  standalone: true,
  templateUrl: './navigation-tabs-menu.component.html',
  styleUrls: ['./navigation-tabs-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, RouterLinkWithHref, RouterLinkActive, MatLegacyTabsModule],
})
export class NavigationTabsMenuComponent {
  @HostBinding() readonly class = 'mp-navigation-tabs-menu';

  @Input() tabLinks: TabLink[] = [];

  trackByFn(_index: number, { link }: TabLink): string {
    return link;
  }
}
