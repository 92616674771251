@if (labelTemplate(); as labelTemplate) {
  <div class="param-wrapper-label truncate">
    <ng-container *ngTemplateOutlet="labelTemplate" />
  </div>
} @else {
  <div class="param-wrapper-label truncate" [ngClass]="{ 'font-bold': boldLabel() }" [title]="label()">
    {{ label() }}
  </div>
}

<ng-content />
