@if (isListExpanded()) {
  <span class="expand-button truncate" [class]="{ 'expand-button--small': useSmallChips() }">
    <span
      class="expand-button__label truncate"
      [class]="{ 'expand-button__label--small': useSmallChips() }"
      (click)="onClick()"
      >{{ numberOfHiddenElements() }} weitere Filter anzeigen</span
    >
  </span>
} @else {
  <span class="expand-button truncate" [class]="{ 'expand-button--small': useSmallChips() }">
    <span
      class="expand-button__label truncate"
      [class]="{ 'expand-button__label--small': useSmallChips() }"
      (click)="onClick()"
      >Weniger anzeigen</span
    >
  </span>
}
