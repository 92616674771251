<a
  class="link"
  matRipple
  [routerLink]="[path()]"
  [routerLinkActive]="isGroup() ? 'expanded' : 'active'"
  [routerLinkActiveOptions]="{ exact: path() === '/' }"
  #rla="routerLinkActive"
  #link
>
  @if (addChildItemLine()) {
    <div class="child-item-line"></div>
  }

  <mat-icon class="icon" [ngClass]="rla.isActive ? '' : 'material-icons-outlined'">
    {{ icon() }}
  </mat-icon>
  @if (label()) {
    <span class="item__label truncate">{{ label() }}</span>
  } @else {
    <span class="item__label truncate"><ng-content /></span>
  }
</a>

@if (isGroup()) {
  <div class="group-content" #groupcontent>
    <span class="group-label">{{ label() }}</span>
    <ng-content select="mp-nav-item" />
  </div>
}
