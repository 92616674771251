import { ChangeDetectionStrategy, Component, OutputEmitterRef, WritableSignal, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { SearchFieldModule } from '../../../search-field/search-field.module';

@Component({
  selector: 'mp-selector-base',
  standalone: true,
  templateUrl: './selector-base.component.html',
  styleUrl: './selector-base.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatLegacyButtonModule, SearchFieldModule],
})
export class SelectorBaseComponent {
  readonly searched: OutputEmitterRef<string> = output<string>();

  protected readonly searchTerm: WritableSignal<string> = signal<string>('');

  onSearchTermChange(searchTerm: string): void {
    this.searchTerm.set(searchTerm);
    this.searched.emit(searchTerm);
  }

  clearSearchTerm(): void {
    this.searchTerm.set('');
    this.searched.emit('');
  }
}
