<h2>{{ title | stripHTML | stripQuotes }}</h2>
<div class="image" [ngClass]="{ 'image--original-size': showImagesInOriginalSize() }">
  <img class="article-image" [src]="imagePath()" />
</div>

<mp-image-gallery-viewer-controller
  class="viewer-controller"
  [(selectedImageIndex)]="selectedImageIndex"
  [imagesNumber]="images.length"
  [showOriginalSizeCheckbox]="true"
  [(showImagesInOriginalSize)]="showImagesInOriginalSize"
/>

<button mat-icon-button class="dialog-close-button" (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>
