import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mp-export-button',
  standalone: true,
  templateUrl: './export-button.component.html',
  styleUrl: './export-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
})
export class ExportButtonComponent {
  @Input() isProcessing = false;
  @Input() disabled = false;
  @Input() disabledStateTooltipText = '';

  @Output() readonly clicked = new EventEmitter<void>();

  emitButtonClicked(): void {
    this.clicked.emit();
  }
}
