import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { GraduationValueType } from '@core/shared/domain';

import { GraduationValue } from '../../models';

@Pipe({
  name: 'mpPriceValidityDates',
  standalone: true,
})
export class PriceValidityDatesPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(graduationValue: GraduationValue): string {
    if (graduationValue.type !== GraduationValueType.Price) {
      return '';
    }

    const { validFrom, validUntil } = graduationValue;

    if (!validFrom && !validUntil) {
      return '';
    }

    const validFromMessage = validFrom ? `Von: ${this.getFormattedDate(validFrom)}` : '';

    const validUntilMessage = validUntil ? `Bis: ${this.getFormattedDate(validUntil)}` : '';

    return `Preisgültigkeit
    ${validFromMessage}
    ${validUntilMessage}`;
  }

  private getFormattedDate(date: Date): string {
    return formatDate(date, 'dd.MM.yyyy', this.locale);
  }
}
