import { SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OutputEmitterRef,
  Signal,
  WritableSignal,
  computed,
  input,
  output,
  signal,
} from '@angular/core';

import { ChipProperties } from './chip-properties';
import { CustomChipComponent } from './custom-chip/custom-chip.component';
import { ExpandChipComponent } from './expand-chip/expand-chip.component';
import { RemoveChip } from './remove-chip';

@Component({
  selector: 'mp-custom-chips',
  standalone: true,
  templateUrl: './custom-chips.component.html',
  styleUrl: './custom-chips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SlicePipe, CustomChipComponent, ExpandChipComponent],
})
export class CustomChipsComponent {
  readonly useSmallChips: InputSignal<boolean> = input<boolean>(false);
  readonly groupName: InputSignal<string> = input<string>('');
  readonly chips: InputSignal<ChipProperties[]> = input.required<ChipProperties[]>();

  readonly remove: OutputEmitterRef<RemoveChip> = output<RemoveChip>();

  readonly DISPLAY_NUMBER_OF_CHIPS = 3;

  protected readonly isListExpanded: WritableSignal<boolean> = signal<boolean>(true);

  protected readonly listLength: Signal<number> = computed(() =>
    this.isListExpanded() ? this.DISPLAY_NUMBER_OF_CHIPS : this.chips().length,
  );

  expandList(): void {
    this.isListExpanded.set(!this.isListExpanded());
  }

  removeChip(removedChip: RemoveChip): void {
    this.remove.emit(removedChip);
  }
}
