<div class="carousel-container">
  <div class="carousel-container__navigation-prev" #navigationPrev>
    <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
  </div>

  <div class="carousel swiper" #swiperContainer>
    <div class="carousel__slides-wrapper swiper-wrapper">
      <div class="carousel__slide swiper-slide" *ngFor="let slideData of slidesData; trackBy: trackByFn">
        <ng-template
          [ngTemplateOutlet]="slideTemplate"
          [ngTemplateOutletContext]="{ $implicit: slideData }"
        ></ng-template>
      </div>
    </div>

    <div class="carousel__scrollbar swiper-scrollbar" #scrollbar></div>
  </div>

  <div class="carousel-container__navigation-next" #navigationNext>
    <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
  </div>
</div>
