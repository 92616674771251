import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mp-rich-text-editor-toolbar][quill-editor-toolbar]',
  standalone: true,
  templateUrl: './rich-text-editor-toolbar.component.html',
  styleUrls: ['./rich-text-editor-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorToolbarComponent {
  @HostBinding('class') readonly class = 'mp-rich-text-editor-toolbar';
}
