@if (showFieldSelector) {
  <mp-query-field-selector
    class="query-field-selector"
    [selectedField]="field"
    [fieldOptions]="fieldOptions"
    (selectedFieldChange)="onSearchQueryFieldSelected($event)"
  />
}

<mp-search-field
  #searchField
  class="raised search-field"
  [ngClass]="{ stacked: showFieldSelector }"
  [placeholder]="searchFieldPlaceholder"
  [value]="searchTerm"
  (searchTerm)="onSearch($event)"
  (searched)="onManualSearch($event)"
>
  @if (searchField.value.length > 0) {
    <button mat-icon-button mpSearchFieldButton="right" (click)="onSearch(''); onManualSearch('')">
      <mat-icon>clear</mat-icon>
    </button>
  }

  <button mat-icon-button mpSearchFieldButton="right" (click)="onManualSearch(searchField.value.trim())">
    <mat-icon>search</mat-icon>
  </button>
</mp-search-field>
