<div
  ngx-dropzone
  #fileUploadDropzone
  data-test="upload-form__dropzone"
  class="dropzone"
  [multiple]="allowMultipleUploads"
  [accept]="acceptedFileTypes"
  [maxFileSize]="maxFileBytes"
  [disabled]="disabled"
  (change)="onChange($event)"
  (click)="onDropzoneClick()"
  [ngClass]="{
    'dropzone--success': status === UploadFormStatus.Success,
    'dropzone--error': status === UploadFormStatus.Error,
    'dropzone--upload': status === UploadFormStatus.Uploading
  }"
>
  <div
    class="dropzone__content"
    data-test="upload-form__dropzone-content"
    *ngIf="status !== UploadFormStatus.Uploading; else uploadingIndicator"
  >
    <ng-content></ng-content>
  </div>

  <ng-template #uploadingIndicator>
    <div class="uploading-indicator">
      <mat-progress-bar
        data-test="upload-form__uploading-indicator"
        [mode]="uploadingProgress !== undefined ? 'determinate' : 'indeterminate'"
        [value]="uploadingProgress ?? 0"
      ></mat-progress-bar>
    </div>
  </ng-template>
</div>
