<button
  *ngIf="isAllItemsSelected; else selectAllItemsButton"
  mat-fab
  color="primary"
  class="deselect-all-button"
  [disabled]="isNoItemsSelected"
  (click)="deselectAll()"
>
  <mat-icon>playlist_remove</mat-icon>
</button>

<ng-template #selectAllItemsButton>
  <button
    mat-fab
    color="primary"
    class="select-all-button"
    (click)="selectAll()"
  >
    <mat-icon>playlist_add_check</mat-icon>
  </button>
</ng-template>
