import { Directive, ElementRef, HostListener, InputSignal, input } from '@angular/core';

import { CustomOverlayConfig } from '../flyout/custom-overlay/custom-overlay-config';
import { CustomOverlayRef } from '../flyout/custom-overlay/custom-overlay-ref';

import { TooltipContentComponent, TooltipOverlayData } from './tooltip-content/tooltip-content.component';
import { TooltipOverlayService } from './tooltip-overlay.service';

/**
 * Custom tooltip directive that displays a tooltip when the user hovers over the host element.
 * It imitates the matTooltip directive basic behavior with the addition of the ability to display HTML content,
 * as it is impossible to do with the matTooltip directive (authors clearly state that this behavior will not be implemented).
 *
 * More info in the github issue: https://github.com/angular/components/issues/5440
 */
@Directive({
  selector: '[mpTooltip]',
  standalone: true,
  providers: [TooltipOverlayService],
})
export class TooltipDirective {
  /**
   * The text to display in the tooltip.
   * The text can contain HTML content, which then will be sanitized and rendered in the tooltip.
   */
  readonly tooltipText: InputSignal<string> = input.required<string>({ alias: 'mpTooltip' });

  private customOverlayRef: CustomOverlayRef<TooltipContentComponent> | undefined;

  constructor(
    private readonly tooltipOverlayService: TooltipOverlayService,
    private readonly elementRef: ElementRef,
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.removeExistingOverlay();

    const tooltipConfig: CustomOverlayConfig<TooltipOverlayData> = {
      payload: { tooltipTextSignal: this.tooltipText },
    };
    this.customOverlayRef = this.tooltipOverlayService.open(TooltipContentComponent, tooltipConfig, this.elementRef);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.removeExistingOverlay();
  }

  private removeExistingOverlay(): void {
    this.customOverlayRef?.close();
  }
}
