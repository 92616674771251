/**
 * Enum for the different possible graduation value types
 */
export enum GraduationValueType {
  Amount = 'Amount',
  Duration = 'Duration',
  Logistic = 'Logistic',
  Percent = 'Percent',
  Price = 'Price',
}
