import { NgModule } from '@angular/core';

import { RelationListItemComponent } from './relation-list-item.component';
import { RelationListComponent } from './relation-list.component';

@NgModule({
  imports: [RelationListComponent, RelationListItemComponent],
  exports: [RelationListComponent, RelationListItemComponent],
})
export class RelationListModule {}
