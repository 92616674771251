import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ArticleType } from '@core/shared/domain';
import { IconDirective } from '@core/shared/util';

@Component({
  selector: 'mp-article-type-icon',
  standalone: true,
  templateUrl: './article-type-icon.component.html',
  styleUrls: ['./article-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, IconDirective],
})
export class ArticleTypeIconComponent {
  @HostBinding('class') readonly class = 'mp-article-type-icon';

  @HostBinding('class.mp-article-type-icon--small')
  @Input()
  small = false;

  @Input() hasMatch = false;
  @Input() type: ArticleType = ArticleType.ClinicArticle;

  @HostBinding('class.mp-article-type-icon--global-article')
  @Input()
  isGlobalArticle = false;

  protected get icon(): string {
    if (this.isGlobalArticle) {
      return this.getGlobalArticleIcon();
    }

    return this.getLocalArticleIcon();
  }

  private getGlobalArticleIcon(): string {
    switch (this.type) {
      case ArticleType.ClinicArticle:
        return '@globalclinicarticle'; /*'mdi:home-city-outline'*/
      case ArticleType.IndustryArticle:
        return '@globalindustryarticle'; /*mdi:factory*/
      default:
        return '@mdi:help';
    }
  }

  private getLocalArticleIcon(): string {
    // The factory of mdi looks different
    // Use normal clinic icon due to different scaling
    switch (this.type) {
      case ArticleType.ClinicArticle:
        return 'home_work';
      case ArticleType.IndustryArticle:
        return 'factory';
      default:
        return '@mdi:help';
    }
  }
}
