import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mp-action-main-content',
  standalone: true,
  templateUrl: './action-main-content.component.html',
  styleUrls: ['./action-main-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMainContentComponent {
  @HostBinding('class') readonly class = 'mp-action-main-content';
}
