import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-selector-item',
  standalone: true,
  templateUrl: './selector-item.component.html',
  styleUrl: './selector-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatRippleModule, MatIconModule],
})
export class SelectorItemComponent {
  @HostBinding('class') readonly class = 'mp-selector-item';

  readonly selected: ModelSignal<boolean> = model<boolean>(false);

  readonly disabled: InputSignal<boolean> = input<boolean>(false);

  readonly toggleable: InputSignal<boolean> = input<boolean>(true);

  readonly toggled: OutputEmitterRef<boolean> = output<boolean>();

  onCardClick(): void {
    if (this.disabled() || !this.toggleable()) {
      return;
    }

    this.selected.set(!this.selected());
    this.toggled.emit(this.selected());
  }
}
