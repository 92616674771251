import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  InputSignal,
  ModelSignal,
  TemplateRef,
  input,
  model,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCardModule } from '@angular/material/legacy-card';

import { ExpandButtonComponent } from './expand-button/expand-button.component';

@Component({
  selector: 'mp-expandable-panel',
  standalone: true,
  templateUrl: './expandable-panel.component.html',
  styleUrl: './expandable-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgTemplateOutlet, MatExpansionModule, MatLegacyCardModule, ExpandButtonComponent],
})
export class ExpandablePanelComponent {
  @HostBinding() readonly class = 'mp-expandable-panel';

  readonly panelTitle: InputSignal<string> = input<string>('');

  readonly expanded: ModelSignal<boolean> = model.required<boolean>();

  readonly expandButtonLabel: InputSignal<string> = input.required<string>();

  readonly panelHeaderTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<
    TemplateRef<unknown> | undefined
  >();

  @ContentChild(TemplateRef)
  protected expansionTemplate!: TemplateRef<unknown>;

  togglePanelExpanded(): void {
    this.expanded.set(!this.expanded());
  }

  setPanelExpanded(value: boolean): void {
    this.expanded.set(value);
  }
}
