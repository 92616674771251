import { NgModule } from '@angular/core';

import { OverviewItemComponent } from './overview-item.component';
import { OverviewComponent } from './overview.component';

@NgModule({
  imports: [OverviewComponent, OverviewItemComponent],
  exports: [OverviewItemComponent, OverviewComponent],
})
export class OverviewModule {}

