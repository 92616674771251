import { ChangeDetectionStrategy, Component, InputSignal, WritableSignal, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SpinnerComponent } from '../spinner/spinner.component';

enum ImageLoadingState {
  Loading,
  Loaded,
  Error,
}

@Component({
  selector: 'mp-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, SpinnerComponent],
})
export class ImageComponent {
  /**
   * Image full path. This value is used directly to fetch the image.
   */
  readonly imagePath: InputSignal<string | null | undefined> = input.required<string | null | undefined>();

  /**
   * The alternative text to display.
   */
  readonly alternateText: InputSignal<string | undefined> = input<string>();

  readonly state: WritableSignal<ImageLoadingState> = signal<ImageLoadingState>(ImageLoadingState.Loading);

  readonly ImageLoadingState = ImageLoadingState;

  onImgLoad(): void {
    if (this.state() === ImageLoadingState.Loading) {
      this.state.set(ImageLoadingState.Loaded);
    }
  }

  onImgError(): void {
    this.state.set(ImageLoadingState.Error);
  }
}
