import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { SelectOption } from '../../../option';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { IdFn } from '../../models';
import { valueAsId } from '../../utils';
import { SelectorBaseComponent } from '../selector-base/selector-base.component';

import { SelectorElementComponent } from './selector-element/selector-element.component';

@Component({
  selector: 'mp-multi-element-selector',
  standalone: true,
  templateUrl: './multi-element-selector.component.html',
  styleUrl: './multi-element-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectorBaseComponent, SelectorElementComponent, SpinnerComponent],
})
export class MultiElementSelectorComponent<T> {
  readonly selectedElements: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly availableElements: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly isLoading: InputSignal<boolean> = input<boolean>(false);

  readonly idFn: InputSignal<IdFn<T>> = input<IdFn<T>>(valueAsId);

  readonly valueSelect: OutputEmitterRef<T> = output<T>();

  readonly valueDeselect: OutputEmitterRef<T> = output<T>();

  readonly searched: OutputEmitterRef<string> = output<string>();

  onSearch(searchTerm: string): void {
    this.searched.emit(searchTerm);
  }

  selectValue(value: T): void {
    this.valueSelect.emit(value);
  }

  deselectValue(value: T): void {
    this.valueDeselect.emit(value);
  }
}
