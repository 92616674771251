import { ChangeDetectionStrategy, Component, HostBinding, input, InputSignal } from '@angular/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

@Component({
  selector: 'mp-param',
  standalone: true,
  templateUrl: './param.component.html',
  styleUrls: ['./param.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyFormFieldModule, MatLegacyInputModule],
})
export class ParamComponent {
  @HostBinding('class') class = 'mp-param';

  readonly value: InputSignal<string | number | null> = input.required<string | number | null>();

  readonly suffix: InputSignal<string | undefined> = input<string>();
}
