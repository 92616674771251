<span class="ql-formats toolbar-tools-group">
  <button class="ql-bold toolbar-tools-group__tool" title="Fett"></button>
  <button class="ql-italic toolbar-tools-group__tool" title="Kursiv"></button>
  <button class="ql-underline toolbar-tools-group__tool" title="Unterstreichen"></button>
  <button class="ql-strike toolbar-tools-group__tool" title="Durchstreichen"></button>
</span>

<span class="ql-formats toolbar-tools-group">
  <button class="ql-list toolbar-tools-group__tool" value="ordered" title="Geordnete Liste"></button>
  <button class="ql-list toolbar-tools-group__tool" value="bullet" title="Ungeordnete Liste"></button>
</span>

<span class="ql-formats toolbar-tools-group toolbar-tools-group--optional">
  <button class="ql-align toolbar-tools-group__tool" value="center" title="Zentriert ausrichten"></button>
  <button class="ql-align toolbar-tools-group__tool" value="right" title="Rechts ausrichten"></button>
  <button class="ql-align toolbar-tools-group__tool" value="justify" title="Blocksatz"></button>
</span>

<span class="ql-formats toolbar-tools-group toolbar-tools-group--optional">
  <select class="ql-color toolbar-tools-group__tool" title="Schriftfarbe"></select>
  <select class="ql-background toolbar-tools-group__tool" title="Hintergrundfarbe"></select>
</span>

<span class="ql-formats toolbar-tools-group">
  <button class="ql-link toolbar-tools-group__tool" title="Link hinzufügen"></button>
  <button class="ql-image toolbar-tools-group__tool" title="Bild einfügen"></button>
</span>

<span class="ql-formats toolbar-tools-group">
  <button class="ql-clean toolbar-tools-group__tool" title="Formatierung zurücksetzen"></button>
</span>
