import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtilPipesModule } from '@core/shared/util';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'mp-list-results-info',
  standalone: true,
  templateUrl: './list-results-info.component.html',
  styleUrls: ['./list-results-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DecimalPipe, LetDirective, AsyncPipe, UtilPipesModule]
})
export class ListResultsInfoComponent {
  @Input({ required: true }) totalItemsQuantity!: number;

  @Input() selectedItemsQuantity?: number;
}
