import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { PageLink } from '@mp/shared/data-access';

@Component({
  selector: 'mp-header-nav-element',
  standalone: true,
  templateUrl: './header-nav-element.component.html',
  styleUrl: './header-nav-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive, MatRippleModule],
})
export class HeaderNavElementComponent {
  @Input() navLink!: PageLink;

  @Input() disabled = false;

  @Input() exact = false;

  @Output() readonly isActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() readonly activated: EventEmitter<void> = new EventEmitter<void>();

  emitIsActiveChange(isActive: boolean): void {
    this.isActive.emit(isActive);
  }

  onActivated(): void {
    this.activated.emit();
  }
}
