import { ChangeDetectionStrategy, Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';

import { TypedFormArray } from '@core/shared/util';

@Component({
  selector: 'mp-type-selector',
  standalone: true,
  templateUrl: './type-selector.component.html',
  styleUrl: './type-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatLegacyChipsModule, MatLegacyMenuModule, MatIconModule],
})
export class TypeSelectorComponent {
  /* Input Fields */

  @Input() displayProperty?: string;
  @Input() dataProperty?: string;
  @Input() label?: string;

  private _formArray?: TypedFormArray;
  @Input() set formArray(formArray: TypedFormArray) {
    if (formArray) {
      this.selectedItems = this.lookupAvailableValuesUsingFormArrayValues(formArray.value());
      this.repopulateAvailableItems();
      this.registerNewFormArrayBinding(formArray);
    }
  }

  private _selectableItems: Array<any> = [];
  @Input() set selectableItems(items: Array<any> | null) {
    if (items) {
      this._selectableItems = items;
      this.selectedItems = this.lookupAvailableValuesUsingFormArrayValues(this._formArray?.value() ?? []);
      this.repopulateAvailableItems();
    }
  }

  /* Internal Fields */

  availableItems: Array<any> = [];
  selectedItems: Array<any> = [];

  /* Auxiliary Fields (for proper disposing) */

  private valueChangesSubscription?: Subscription;

  constructor(private readonly destroyRef: DestroyRef) {}

  private registerNewFormArrayBinding(formArray: TypedFormArray): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }

    this._formArray = formArray;
    this.valueChangesSubscription = this.subscribeToValueChanges(this._formArray);
  }

  private subscribeToValueChanges(formArray: TypedFormArray): Subscription {
    return formArray
      .valueChanges$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (values: Array<any>) => {
          this.selectedItems = this.lookupAvailableValuesUsingFormArrayValues(values);
          this.repopulateAvailableItems();
        },
      });
  }

  private lookupAvailableValuesUsingFormArrayValues(formArrayValues: Array<any>): Array<any> {
    if (!this._selectableItems) {
      console.warn(
        'Werte aus dem FormArray konnten nicht vollständig auf selectableItems gemapped werden!',
        formArrayValues,
        this._selectableItems,
      );

      return [];
    }

    return (this.selectedItems = this._selectableItems.filter((selectableItem) =>
      formArrayValues.includes(this.getDataValueForItem(selectableItem)),
    ));
  }

  addItem(item: any): void {
    this.selectedItems.push(item);

    const dataValue = this.getDataValueForItem(item);
    if (this._formArray?.hasEntryBuilder()) {
      this._formArray.pushValue(dataValue);
    } else {
      this._formArray?.push(new UntypedFormControl(dataValue));
    }
  }

  removeItem(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }

    this._formArray?.removeAt(index);
  }

  getDisplayValueForItem(item: any): string {
    if (!this.displayProperty) {
      throw Error('"displayProperty" is undefined!');
    }

    return item[this.displayProperty];
  }

  getDataValueForItem(item: any): string {
    const dataOrDisplayProperty = this.dataProperty ?? this.displayProperty;
    if (!dataOrDisplayProperty) {
      throw Error('"displayProperty" is undefined!');
    }

    return item[dataOrDisplayProperty];
  }

  private repopulateAvailableItems(): void {
    if (this._selectableItems) {
      this.availableItems = this._selectableItems.filter(
        (selectableItem) =>
          !this.selectedItems.some((selectedItem) => this.itemsAreEqual(selectableItem, selectedItem)),
      );
    } else {
      this.availableItems = [];
    }
  }

  private itemsAreEqual(item: any, other: any): boolean {
    return this.getDataValueForItem(item) === this.getDataValueForItem(other);
  }
}
