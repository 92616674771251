<small class="label">{{ label }}</small>

<mat-chip-list>
  <mat-chip
    *ngFor="let selectedOption of selectedOptions; trackBy: trackByFn"
    removable="true"
    (removed)="removeSelectedItem(selectedOption.value)"
  >
    {{ selectedOption.label }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>

  <button *ngIf="availableOptions.length" mat-icon-button type="button" color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
  </button>

  <mat-menu #menu="matMenu" (closed)="onTouched()">
    <button
      mat-menu-item
      *ngFor="let option of availableOptions; trackBy: trackByFn"
      (click)="selectItem(option.value)"
    >
      {{ option.label }}
    </button>
  </mat-menu>
</mat-chip-list>
