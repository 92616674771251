<mat-form-field class="search-field" floatLabel="never">
  <mat-icon class="search-field__icon material-icons-outlined" matPrefix>search</mat-icon>
  <input
    #inputElement="matInput"
    matInput
    type="text"
    [value]="value()"
    (input)="value.set(inputElement.value)"
    [matAutocomplete]="autocompleteControl()!"
    [matAutocompleteDisabled]="!autocompleteControl()"
    [placeholder]="searchFieldPlaceholder()"
    (keyup.enter)="search.emit(inputElement.value)"
  />
</mat-form-field>
