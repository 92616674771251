<div class="overview">
  <div class="overview__header">
    <span class="overview__header-title"
      >{{ overviewTitle() }}

      <sup class="overview__header-total-items">{{ totalItems() }}</sup>
    </span>
    <ng-content select="[tab-overview-header-context]" />
  </div>

  <div class="overview__content">
    <mat-card class="overview__content-card">
      <ng-content />
    </mat-card>
  </div>
</div>
