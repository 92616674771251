import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { ElementDirective } from '../core/element';

import { RelationListItemComponent } from './relation-list-item.component';

@Component({
  selector: 'mp-relation-list',
  standalone: true,
  templateUrl: './relation-list.component.html',
  styleUrl: './relation-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule, RelationListItemComponent],
})
export class RelationListComponent extends ElementDirective {
  readonly hideAddButton: InputSignal<boolean> = input<boolean>(false);

  readonly add: OutputEmitterRef<void> = output<void>();

  constructor() {
    super();
    this.class = 'mp-relation-list';
  }
}
