<!-- TODO - consider using virtual scroll or two virtual scroll containers -->
<mp-selector-base (searched)="onSearch($event)">
  <div class="elements-section">
    <p class="elements-section__label">Zugewiesen</p>

    <div class="elements-section__elements">
      @for (element of selectedElements(); track idFn()(element.value)) {
        <mp-selector-element [element]="element" [isSelected]="true" (valueDeselect)="deselectValue($event)" />
      } @empty {
        Keine Elemente ausgewählt
      }
    </div>
  </div>

  <div class="elements-section">
    <p class="elements-section__label">Nicht zugewiesen</p>

    <div class="elements-section__elements">
      @if (!isLoading()) {
        @for (element of availableElements(); track idFn()(element.value)) {
          <mp-selector-element [element]="element" [isSelected]="false" (valueSelect)="selectValue($event)" />
        } @empty {
          Keine Elemente gefunden
        }
      } @else {
        <mp-spinner />
      }
    </div>
  </div>
</mp-selector-base>
