<a
  class="nav-element"
  [class]="{ 'nav-element--disabled': disabled }"
  matRipple
  #rla="routerLinkActive"
  [routerLink]="disabled ? null : navLink"
  [routerLinkActiveOptions]="{ exact }"
  (isActiveChange)="emitIsActiveChange($event)"
  (click)="!disabled && !rla.isActive && onActivated()"
  routerLinkActive="nav-element--active"
>
  <ng-content />
</a>
