import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  Optional,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { LocalStorageService } from '@core/shared/util';
import { InsightsBaseEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { CollapsedFiltersComponent } from './collapsed-filters/collapsed-filters.component';
import { FilterInfo } from './filter-info';

const ASIDE_FILTERS_EXPANSION_STATE_KEY = 'asideFiltersExpanded';

export interface AsideFiltersInsightsEventsConfig {
  filterExpand: InsightsBaseEvent;
  filterCollapse: InsightsBaseEvent;
  filterReset: InsightsBaseEvent;
}

@Component({
  selector: 'mp-aside-filters',
  standalone: true,
  templateUrl: './aside-filters.component.html',
  styleUrl: './aside-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule, CollapsedFiltersComponent],
})
export class AsideFiltersComponent {
  readonly filtersSummary: InputSignal<FilterInfo[]> = input<FilterInfo[]>([]);

  readonly trackInsightsEvents: InputSignal<boolean> = input<boolean>(false);

  readonly filtersReset: OutputEmitterRef<void> = output<void>();

  isExpanded: boolean = this.localStorageService.read(ASIDE_FILTERS_EXPANSION_STATE_KEY, true);

  private readonly insightsEventConfig: AsideFiltersInsightsEventsConfig = {
    filterCollapse: InsightsBaseEvent.FILTERS_COLLAPSE,
    filterExpand: InsightsBaseEvent.FILTERS_EXPAND,
    filterReset: InsightsBaseEvent.FILTER_RESET,
  };

  constructor(
    private readonly localStorageService: LocalStorageService,
    @Optional() readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {}

  toggleExpandedState(): void {
    this.isExpanded = !this.isExpanded;
    this.trackExpansionChangeEvent();
    this.localStorageService.write(ASIDE_FILTERS_EXPANSION_STATE_KEY, this.isExpanded);
  }

  resetFilters(): void {
    if (this.trackInsightsEvents()) {
      this.insightsEventsTrackingService?.trackEvent(this.insightsEventConfig.filterReset);
    }

    this.filtersReset.emit();
  }

  private trackExpansionChangeEvent(): void {
    if (!this.trackInsightsEvents()) {
      return;
    }

    const event: InsightsBaseEvent = this.isExpanded
      ? this.insightsEventConfig.filterExpand
      : this.insightsEventConfig.filterCollapse;

    this.insightsEventsTrackingService?.trackEvent(event);
  }
}
