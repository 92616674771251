import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'mp-data-section',
  standalone: true,
  templateUrl: './data-section.component.html',
  styleUrls: ['./data-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDividerModule],
})
export class DataSectionComponent {
  readonly sectionTitle: InputSignal<string> = input.required<string>();
}
