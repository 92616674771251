import { ChangeDetectionStrategy, Component, Inject, Signal } from '@angular/core';

import { OVERLAY_DATA } from '../../flyout/custom-overlay/custom-overlay-tokens';

export interface TooltipOverlayData {
  tooltipTextSignal: Signal<string>;
}

@Component({
  selector: 'mp-tooltip-content',
  standalone: true,
  templateUrl: './tooltip-content.component.html',
  styleUrl: './tooltip-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContentComponent {
  readonly tooltipText: Signal<string>;

  constructor(@Inject(OVERLAY_DATA) private readonly data: TooltipOverlayData) {
    this.tooltipText = this.data.tooltipTextSignal;
  }
}
