import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OnDestroy,
  OutputEmitterRef,
  Signal,
  computed,
  forwardRef,
  input,
  model,
  output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

import { DEFAULT_ALLOWED_TYPES, FileTypes, isImageValid } from '@core/shared/util';

import { ValueElementDirective } from '../core/value-element';

import { AvatarError } from './avatar-error.enums';
import { DisplaySize } from './display-size.type';

const AVATAR_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AvatarComponent),
  multi: true,
};

@Component({
  selector: 'mp-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  host: {
    '[class.mp-avatar--upload]': 'upload()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule],
  providers: [AVATAR_CONTROL_ACCESSOR],
})
export class AvatarComponent extends ValueElementDirective<File | string> implements OnDestroy {
  /* Content Properties */
  readonly image: ModelSignal<string | null | undefined> = model<string | null | undefined>(null);
  readonly name: InputSignal<string | undefined | null> = input<string | undefined | null>(undefined);

  /* Formatting Properties */
  readonly size: InputSignal<DisplaySize> = input<DisplaySize>('large');

  /* Technical Properties */
  readonly upload: InputSignal<boolean> = input<boolean>(false);

  /* Events */
  readonly removeImage: OutputEmitterRef<void> = output<void>();
  readonly fail: OutputEmitterRef<AvatarError> = output<AvatarError>();

  protected readonly initials: Signal<string> = computed(() => this.buildNameInitials(this.name()));

  protected readonly avatarSizeCssClassObject: Signal<Record<string, boolean>> = computed(() => ({
    'avatar--tiny': this.size() === 'tiny',
    'avatar--small': this.size() === 'small',
    'avatar--medium': this.size() === 'medium',
    'avatar--large': this.size() === 'large',
  }));

  protected readonly acceptedFormats: FileTypes[] = DEFAULT_ALLOWED_TYPES;

  protected readonly placeholderImageUrl = 'assets/Avatar_Placeholder.jpeg';

  private readonly fileSizeLimitInMB = 2;

  constructor() {
    super();
    this.class = 'mp-avatar';
  }

  ngOnDestroy(): void {
    this.revokeExistingImageUrl();
  }

  onFileChange(event: Event): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;

    if (!eventTarget) {
      return;
    }

    const file: File | null = eventTarget.files ? eventTarget.files[0] : null;
    if (!file) {
      return;
    }

    if (!isImageValid(file, this.acceptedFormats)) {
      this.fail.emit(AvatarError.WRONG_FILE_TYPE);

      return;
    }

    if (this.isFileExceedingSizeLimit(file)) {
      this.fail.emit(AvatarError.FILE_SIZE_LIMIT);

      return;
    }

    this.value = file;
    this.preview(file);
  }

  handleImageLoadError(): void {
    this.revokeExistingImageUrl();
    this.image.set(null);
  }

  clearImage(): void {
    this.revokeExistingImageUrl();

    /* Currently this needs to be null, because otherwise a change detection
     * compare (as it's done in the TypedForm) with `undefined` would result in `false`.
     * The preset "empty"-value used when comparing is `null` here due to the backend
     * providing `null` for unset values. */
    this.value = null as any;
    this.image.set(null);
    this.removeImage.emit();
  }

  private buildNameInitials(name?: string | null): string {
    if (!name) {
      return '';
    }

    const partsOfName = name.split(' ').filter((part: string) => !!part && part.length > 0);

    return partsOfName.length >= 2
      ? this.getFirstLetterUppercased(partsOfName[0]) + this.getFirstLetterUppercased(partsOfName[1])
      : this.getFirstLetterUppercased(name);
  }

  private getFirstLetterUppercased(value: string): string {
    return value.charAt(0).toUpperCase();
  }

  private isFileExceedingSizeLimit(file: File): boolean {
    return file.size > this.fileSizeLimitInMB * 1e6;
  }

  private preview(fileData: File): void {
    this.revokeExistingImageUrl();

    this.image.set(URL.createObjectURL(fileData));
  }

  private revokeExistingImageUrl(): void {
    const image: string | null | undefined = this.image();
    if (image) {
      URL.revokeObjectURL(image);
    }
  }
}
