import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mp-filter-badge',
  standalone: true,
  templateUrl: './filter-badge.component.html',
  styleUrl: './filter-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBadgeComponent {
  readonly value: InputSignal<string | number | null> = input.required<string | number | null>();
}
