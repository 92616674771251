import { NgModule } from '@angular/core';

import { SearchFieldButtonDirective } from './search-field-button.directive';
import { SearchFieldComponent } from './search-field.component';

@NgModule({
  imports: [SearchFieldComponent, SearchFieldButtonDirective],
  exports: [SearchFieldComponent, SearchFieldButtonDirective],
})
export class SearchFieldModule {}
