<mp-autocomplete-panel
  #autocompletePanel
  panelClass="{{ class }}__autocomplete-panel {{ panelClass() }}"
  [options]="options()"
  [autoActiveFirstOption]="autoActiveFirstOption()"
  [optionTemplate]="optionTemplate() ?? defaultOptionTemplate.template"
  [emptyOptionsListTemplate]="emptyOptionsListTemplate"
  [hideEmptyOptionsList]="searchTerm().length < MIN_SEARCH_TERM_LENGTH"
  [useVirtualScroll]="useVirtualScroll()"
>
  <ng-template #emptyOptionsListTemplate>
    <div class="no-entries">
      @if (isLoadingOptions()) {
        <mp-spinner />
      } @else {
        <span>Keine Ergebnisse</span>
      }
    </div>
  </ng-template>
</mp-autocomplete-panel>

<ng-template
  #defaultOptionTemplate="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="optionTemplateContextType"
  let-option
>
  <div class="entity-autocomplete-item">
    <h4 class="truncate" [title]="option.label">{{ option.label }}</h4>
    @if (option.subLabel; as optionSubLabel) {
      <span>{{ optionSubLabel }}</span>
    }
  </div>
</ng-template>
