import { KeyValuePipe, NgClass, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  Signal,
  WritableSignal,
  computed,
  input,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { LetDirective } from '@ngrx/component';

import { Graduation, GraduationLevel, GraduationValue } from '../../models';
import { GraduationValueOutputPipe } from '../../pipes';
import { GraduationEffectComponent } from '../graduation-effect/graduation-effect.component';

import { GraduationExpandButtonComponent } from './graduation-expand-button/graduation-expand-button.component';

@Component({
  selector: 'mp-graduation-table',
  standalone: true,
  templateUrl: './graduation-table.component.html',
  styleUrl: './graduation-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    KeyValuePipe,
    SlicePipe,
    LetDirective,

    MatIconModule,
    MatTableModule,

    GraduationValueOutputPipe,
    GraduationEffectComponent,
    GraduationExpandButtonComponent,
  ],
})
export class GraduationTableComponent {
  readonly isFirst: InputSignal<boolean> = input.required<boolean>();

  readonly isFutureCondition: InputSignal<boolean> = input.required<boolean>();

  readonly graduation: InputSignal<Graduation> = input.required<Graduation>();

  readonly showDescriptionColumn: InputSignal<boolean> = input<boolean>(false);

  readonly showEffectColumn: InputSignal<boolean> = input<boolean>(false);

  protected readonly displayedColumns: Signal<string[]> = computed(() => this.getGraduationDisplayedColumns());

  protected readonly isExpanded: WritableSignal<boolean> = signal<boolean>(false);

  protected readonly hiddenElementsCount: Signal<number> = computed(() => this.getGraduationHiddenLevelsCount());

  protected readonly hasMore: Signal<boolean> = computed(() => this.hiddenElementsCount() > 0);

  protected readonly visibleGraduationLevelsLimit: Signal<number | undefined> = computed(() =>
    this.getVisibleGraduationLevelsLimit(),
  );

  protected readonly MAX_COLLAPSED_LEVELS_COUNT = 5;

  protected readonly DESCRIPTION_COLUMN_NAME = 'description';

  protected readonly GRADUATION_EFFECT_COLUMN_NAME = 'graduationEffect';

  getGraduationColumnName(colIndex: number): string {
    return `graduationName${colIndex}`;
  }

  getGraduationLevelRequirement(graduationLevel: GraduationLevel, colIndex: number): GraduationValue | undefined {
    return graduationLevel.requirements ? graduationLevel.requirements[colIndex] : undefined;
  }

  trackByFn(index: number): number {
    return index;
  }

  private getGraduationDisplayedColumns(): string[] {
    const displayedColumns: string[] = this.graduation().graduationNames.map((_, index) =>
      this.getGraduationColumnName(index),
    );

    if (this.showEffectColumn()) {
      displayedColumns.push(this.GRADUATION_EFFECT_COLUMN_NAME);
    }

    if (this.showDescriptionColumn()) {
      displayedColumns.push(this.DESCRIPTION_COLUMN_NAME);
    }

    return displayedColumns;
  }

  private getGraduationHiddenLevelsCount(): number {
    return (this.graduation().levels?.length ?? 0) - this.MAX_COLLAPSED_LEVELS_COUNT;
  }

  private getVisibleGraduationLevelsLimit(): number | undefined {
    return this.hasMore() && !this.isExpanded() ? this.MAX_COLLAPSED_LEVELS_COUNT : undefined;
  }
}
