@if (state() === ImageLoadingState.Loaded || state() === ImageLoadingState.Loading) {
  <img
    class="image"
    [class.image--loading]="state() === ImageLoadingState.Loading"
    [alt]="alternateText()"
    [src]="imagePath()"
    (load)="onImgLoad()"
    (error)="onImgError()"
  />

  @if (state() === ImageLoadingState.Loading) {
    <div class="placeholder">
      <mp-spinner class="placeholder__spinner" />
    </div>
  }
} @else {
  <div class="placeholder">
    <mat-icon class="placeholder__image-icon" svgIcon="mdi:image" />
  </div>
}
