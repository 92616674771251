import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { SelectOption } from '../models';

@Component({
  selector: 'mp-option',
  standalone: true,
  // eslint-disable-next-line quotes
  template: `<span [ngClass]="{ 'font-bold': !option().isResetOption }">{{ option().label }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class OptionComponent {
  readonly option: InputSignal<SelectOption<unknown>> = input.required<SelectOption<unknown>>();
}
