import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FlyoutManager } from '@core/shared/util';

import { SelectionItem } from '../flyout/selector/selection-item/selection-item';
import { wrapInSelectionItems } from '../flyout/selector/selection-item/selection-item-operator';
import { SelectorFlyoutRef } from '../flyout/selector/selector-flyout-ref';
import { SelectorFlyoutService } from '../flyout/selector/selector-flyout.service';
import { AssignedOrganization } from './assigned-organization';

@Injectable()
export class OrganizationSelectorFlyoutFacade extends FlyoutManager {
  constructor(private readonly flyoutSelectorService: SelectorFlyoutService) {
    super();
  }

  promptSingleOrganisationFlyout(
    selectionItems$: Observable<AssignedOrganization[]>,
  ): SelectorFlyoutRef<AssignedOrganization> | undefined {
    if (this.flyoutCurrentlyOpened) {
      return undefined;
    }

    const flyoutRef: SelectorFlyoutRef<AssignedOrganization> = this.flyoutSelectorService.openSingleSelection(
      'Organisationen auswählen',
      selectionItems$,
    );

    this.markFlyoutAsOpen();
    this.subscribeToAfterClosed(flyoutRef, () => this.markFlyoutAsClosed());

    return flyoutRef;
  }

  promptOrganisationFlyout(
    selectionItems$: Observable<SelectionItem<AssignedOrganization>[]>,
  ): SelectorFlyoutRef<SelectionItem<AssignedOrganization>> | undefined {
    if (this.flyoutCurrentlyOpened) {
      return undefined;
    }

    const flyoutRef: SelectorFlyoutRef<SelectionItem<AssignedOrganization>> =
      this.flyoutSelectorService.openMultipleSelection('Organisationen auswählen', selectionItems$, {
        useVirtualScroll: true,
      });

    this.markFlyoutAsOpen();
    this.subscribeToAfterClosed(flyoutRef, () => this.markFlyoutAsClosed());

    return flyoutRef;
  }

  buildOrganisationSelectionItems$(
    selectableOptions$: Observable<AssignedOrganization[]>,
    optionsToExclude: string[],
  ): Observable<SelectionItem<AssignedOrganization>[]> {
    const alreadyAttached = (option: AssignedOrganization) => optionsToExclude.some((id) => id === option.organizationId);

    return selectableOptions$.pipe(take(1), wrapInSelectionItems({ header: 'name' }, { exclude: alreadyAttached }));
  }
}
