@if (featureFlags().customHeader) {
  <div class="header" *ngrxLet="headerElementsMap$ as headerElementMap">
    <div class="header__logo-container">
      <a class="hidden sm:inline" routerLink="/">
        <img class="header__logo" alt="App Logo" [src]="'app_header_logo.png' | mpThemeAssetsPath | async" />
      </a>

      <!-- On low resolution in place of logo we display toggle sidenav button to make it accessible -->
      <button mat-icon-button (click)="toggleSidenav()" class="toggle-sidenav-button sm:!hidden">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <div class="header-elements">
      @for (placement of headerElementPlacements; track $index) {
        <div
          class="header-elements__element"
          [ngClass]="{ 'header-elements__element--center': placement === 'center' }"
        >
          @for (elementTemplate of headerElementMap[placement]; track $index) {
            <ng-container *ngTemplateOutlet="elementTemplate; context: { $implicit: activeRouteTitle() }" />
          }
        </div>
      }
    </div>
  </div>
} @else {
  <button mat-icon-button (click)="toggleSidenav()" class="toggle-sidenav-button">
    <mat-icon>menu</mat-icon>
  </button>

  @if (!showAppLogo()) {
    <a mat-button routerLink="/" class="start-page-navigation-button">
      {{ appTitle() }}
    </a>
  }

  @if (showAppLogo()) {
    <img class="logo" alt="App Logo" [src]="'app_logo.png' | mpThemeAssetsPath | async" />
  }
}
