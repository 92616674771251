import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef } from '@angular/core';

export class CustomOverlayRef<T> {
  private _componentInstance?: T;
  get componentInstance(): T | undefined {
    return this._componentInstance;
  }
  set componentInstance(value: T | undefined) {
    this._componentInstance = value;
  }
  private componentRef?: ComponentRef<T>;

  constructor(protected overlayRef?: OverlayRef) {}

  close(): void {
    this.disposeAndFreeFromMemory();
  }

  initializeWithComponentRef(componentRef: ComponentRef<T>): void {
    this.componentRef = componentRef;
    this.componentInstance = componentRef.instance;
  }

  protected disposeAndFreeFromMemory(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.overlayRef = undefined;

    this.componentRef?.destroy();
    this.componentRef = undefined;

    this.componentInstance = undefined;
  }
}
