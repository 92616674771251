export function valueAsId(v: unknown): string | number | undefined {
  if (v == null) {
    return undefined;
  }

  if (typeof v === 'string' || typeof v === 'number') {
    return v;
  }

  throw new Error('For complex objects, custom id function must be provided');
}
