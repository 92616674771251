<button #decrease mat-icon-button matSuffix color="accent" [disabled]="menge <= min" (click)="decreaseValue()">
  <mat-icon>remove</mat-icon>
</button>
<mat-form-field>
  <input
    #input
    matInput
    type="number"
    [value]="menge"
    [step]="step"
    [min]="min"
    [max]="max"
    (change)="onInputChange(input.value, input)"
  />
</mat-form-field>
<button #increase mat-icon-button matSuffix color="accent" [disabled]="menge >= max" (click)="increaseValue()">
  <mat-icon>add</mat-icon>
</button>
