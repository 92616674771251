import { NgClass, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mp-catalog-covin-badge',
  standalone: true,
  templateUrl: './catalog-covin-badge.component.html',
  styleUrl: './catalog-covin-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, PercentPipe],
})
export class CatalogCovinBadgeComponent {
  @HostBinding('class') readonly class = 'mp-catalog-covin-badge';

  _value = 0;
  _currentValue = 0;
  math = Math;

  @Input() set value(val: number | undefined | null) {
    this._value = val ? parseFloat(val.toFixed(2)) : 0;
  }

  @Input() set currentValue(_currentValue: number | undefined | null) {
    this._currentValue = _currentValue ? parseFloat(_currentValue.toFixed(2)) : 0;
  }

  @Input() hideLabel = false;
}
