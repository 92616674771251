@if (showOriginalSizeCheckbox()) {
  <mat-checkbox [checked]="showImagesInOriginalSize()" (change)="toggleShowImagesInOriginalSize()">
    Bilder in Originalgröße anzeigen
  </mat-checkbox>
}

@if (imagesNumber() > 1) {
  <button mat-icon-button [disabled]="selectedImageIndex() === 0" (click)="selectPreviousImageIndex()">
    <mat-icon>navigate_before</mat-icon>
  </button>
  {{ selectedImageIndex() + 1 }} / {{ imagesNumber() }}
  <button mat-icon-button [disabled]="selectedImageIndex() === imagesNumber() - 1" (click)="selectNextImageIndex()">
    <mat-icon>navigate_next</mat-icon>
  </button>
}
