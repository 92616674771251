<mat-icon
  *ngFor="let filterSummary of filtersSummary; trackBy: trackByFn"
  class="collapsed-filter material-icons-outlined"
  [ngClass]="{
    'collapsed-filter--active': filterSummary.isActive,
    'collapsed-filter--hidden': filterSummary.hidden
  }"
  [matTooltip]="filterSummary.title"
  matTooltipPosition="right"
  [mpIcon]="filterSummary.icon"
></mat-icon>
