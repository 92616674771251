<mp-selector-base (searched)="onSearch($event)">
  @if (!isLoading()) {
    <!-- TODO - consider using virutal scroll -->
    @for (option of options(); track idFn()(option.value)) {
      <mp-selector-item
        class="list-item"
        *ngrxLet="selectionModel.isSelected(option.value) as isSelected"
        [selected]="isSelected"
        [toggleable]="itemsDeselactable() || !isSelected"
        (toggled)="onOptionToggle(option.value)"
      >
        @if (optionTemplate(); as optionTemplate) {
          <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option.value }" />
        } @else {
          <h4>{{ option.label }}</h4>

          @if (option.subLabel; as subLabel) {
            <span class="list-item__sub-label">{{ subLabel }}</span>
          }
        }
      </mp-selector-item>
    } @empty {
      {{ noOptionsLabel() }}
    }
  } @else {
    <mp-spinner />
  }
</mp-selector-base>
