<cdk-virtual-scroll-viewport [itemSize]="itemHeight">
  <ng-container *cdkVirtualFor="let item of dataSource ?? []">
    <ng-container *ngTemplateOutlet="itemTemplate ?? itemCard; context: { $implicit: item }" />
  </ng-container>

  @if (dataSource?.isLoading$ | async) {
    <ng-template [ngTemplateOutlet]="loadingFooterTemplate ?? footerLoading" />
  } @else {
    <ng-template [ngTemplateOutlet]="noMoreFooterTemplate ?? footerNoMore" />
  }
</cdk-virtual-scroll-viewport>

<ng-template #itemCard let-item>
  <mat-card class="item-card">
    <h2>{{ item.header }}</h2>
    <span>{{ item.subheader }}</span>
  </mat-card>
</ng-template>

<ng-template #footerMore>
  <div class="footer footer-more">
    <small>Weitere Einträge laden...</small>
  </div>
</ng-template>

<ng-template #footerNoMore>
  <div class="footer footer-no-more">
    <small> Alle {{ dataSource.length | autoSingularOrPlural: 'Eintrag' : 'Einträge' }} geladen </small>
  </div>
</ng-template>

<ng-template #footerLoading>
  <div class="footer footer-loading">
    <mp-spinner />
  </div>
</ng-template>

