import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  QueryList,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule, MatLegacyMenuItem as MatMenuItem } from '@angular/material/legacy-menu';

@Component({
  selector: 'mp-dropdown-button',
  standalone: true,
  templateUrl: './dropdown-button.component.html',
  styleUrl: './dropdown-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatLegacyMenuModule, MatIconModule],
})
export class DropdownButtonComponent {
  @HostBinding('class') readonly class = 'mp-dropdown-button';

  readonly buttonDisabled: InputSignal<boolean> = input.required<boolean>();
  readonly dropdownDisabled: InputSignal<boolean> = input.required<boolean>();

  readonly buttonClick: OutputEmitterRef<MouseEvent> = output<MouseEvent>();
  readonly dropdownClick: OutputEmitterRef<MouseEvent> = output<MouseEvent>();

  @ContentChildren(MatMenuItem) private dropdownItems!: QueryList<MatMenuItem>;

  emitButtonClick(event: MouseEvent): void {
    this.buttonClick.emit(event);
  }

  emitDropdownClick(event: MouseEvent): void {
    this.dropdownClick.emit(event);
  }

  get hasDropdown(): boolean {
    return this.dropdownItems?.length > 0;
  }
}
