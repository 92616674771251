import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'mp-action-header',
  standalone: true,
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDividerModule],
})
export class ActionHeaderComponent {}
