import { Directive, ElementRef, HostListener } from '@angular/core';

interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

interface DragEvent extends MouseEvent {
  dataTransfer: DataTransfer;
}

@Directive({
  selector: 'mp-digits, [mpDigits]',
  exportAs: 'digits',
  standalone: true,
})
export class DigitsDirective {
  private readonly navigationKeys: Array<string> = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  inputElement: HTMLInputElement;

  constructor(hostElement: ElementRef) {
    this.inputElement = hostElement.nativeElement;
  }

  // eslint-disable-next-line complexity
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    // Should do nothing if the default action has been cancelled.
    if (event.defaultPrevented) {
      return;
    }

    // Should do nothing if the event.key is a navigation key.
    if (this.navigationKeys.indexOf(event.key) > -1) {
      return;
    }

    // Checks if ctrl key or meta key (mac: cmd) is pressed.
    if (event.ctrlKey || event.metaKey) {
      // Should do nothing if a specific shortcut is given.
      switch (event.key) {
        case 'a':
        case 'c':
        case 'v':
        case 'x':
          return;
        default:
      }
    }

    if (event.key === ' ' || isNaN(Number(event.key))) {
      // Key is not a numeric value. Input is getting prevented.
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    if (!event) {
      return;
    }

    const pastedInput = event.clipboardData?.getData('text/plain');
    if (!pastedInput) {
      return;
    }

    const pasted = document.execCommand('insertText', false, pastedInput.replace(/[^0-9]/g, ''));

    if (pasted) {
      event.preventDefault();
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(pastedInput);
        document.execCommand('paste');
      }
    }
  }

  @HostListener('drop', ['$event']) onDrop(event?: DragEvent): void {
    if (!event) {
      return;
    }

    this.inputElement.focus();

    const textData = event.dataTransfer?.getData('text');
    if (!textData) {
      return;
    }

    const pasted = document.execCommand('insertText', false, textData.replace(/[^0-9]/g, ''));

    if (pasted) {
      event.preventDefault();
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textData);
        document.execCommand('paste');
      }
    }
  }
}
