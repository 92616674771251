import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { NotificationService } from '@core/shared/util';

@Component({
  selector: 'mp-numeric-spinner',
  standalone: true,
  templateUrl: './numeric-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatInputModule],
})
export class NumericSpinnerComponent implements OnDestroy, AfterViewInit {
  @HostBinding('class') readonly class = 'mp-numeric-spinner';

  @ViewChild('decrease', { read: ElementRef })
  readonly decrease!: ElementRef;

  @ViewChild('increase', { read: ElementRef })
  readonly increase!: ElementRef;

  @Output() readonly mengeChange: EventEmitter<number>;

  @Input() menge = 1;
  @Input() min = 1;
  @Input() max = 100000;
  @Input() step = 1;
  @Input() debounceTime = 0;

  constructor(
    private readonly toaster: NotificationService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.mengeChange = new EventEmitter<number>();
  }

  ngAfterViewInit(): void {
    merge(fromEvent(this.increase.nativeElement, 'click'), fromEvent(this.decrease.nativeElement, 'click'))
      .pipe(
        debounceTime(this.debounceTime),
        map(() => this.menge),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.mengeChange.emit(this.menge);
        },
      });
  }

  ngOnDestroy(): void {
    this.mengeChange.complete();
  }

  increaseValue(): void {
    if (this.menge < this.max) {
      this.menge += this.step;
    }
  }

  decreaseValue(): void {
    if (this.menge > this.min) {
      this.menge -= this.step;
    }
  }

  onInputChange(value: string, inputElement: HTMLInputElement): void {
    try {
      const parsedValue: number = parseInt(value);

      const isValid = this.validateValue(parsedValue);

      if (isValid) {
        this.setValue(parsedValue);
      } else {
        this.reset(inputElement);
      }
    } catch (error) {
      this.reset(inputElement);
    }
  }

  private validateValue(value: number): boolean {
    const smallerThanMin = value < this.min;
    const biggerThanMax = value > this.max;
    const isMultipleOfStep = value % this.step === 0;

    const isValid = !smallerThanMin && !biggerThanMax && isMultipleOfStep;

    if (biggerThanMax) {
      this.toaster.toastDanger(`Der Wert darf nicht größer als ${this.max.toLocaleString()} sein.`);
    } else if (smallerThanMin) {
      this.toaster.toastDanger(`Der Wert darf nicht kleiner als ${this.min.toLocaleString()} sein.`);
    }

    return isValid;
  }

  private setValue(value: number): void {
    this.menge = value;
    this.mengeChange.emit(this.menge);
  }

  private reset(inputElement: HTMLInputElement): void {
    this.setValue(this.min);
    inputElement.value = this.menge.toString();
  }
}
