import type Quill from 'quill';
import type BaseTheme from 'quill/themes/base';
import { type BaseTooltip } from 'quill/themes/base';

export function richTextEditorLinkHandler(quillEditor: Quill): void {
  const theme: BaseTheme = quillEditor.theme as BaseTheme;
  const tooltip: BaseTooltip | undefined = theme.tooltip as BaseTooltip | undefined;

  if (!tooltip) {
    return;
  }

  tooltip.edit('link');

  if (tooltip.textbox) {
    tooltip.textbox.placeholder = 'URL';
  }
}
