import { Observable } from 'rxjs';

import { SelectOption } from '../option';

export type AutocompleteOptionsFetcher<T = unknown> = (
  searchTerm: string | undefined,
  options?: SelectOption<T>[],
) => SelectOption<T>[] | Observable<SelectOption<T>[]>;

export function defaultOptionsFetcher<T = unknown>(
  searchTerm: string | undefined,
  options?: SelectOption<T>[],
): SelectOption<T>[] {
  if (!options) {
    return [];
  }

  if (!searchTerm) {
    return options;
  }

  const lowercaseSearchTerm = searchTerm.toLowerCase();

  return options.filter((item) => {
    const lowercaseLabel = item.label.toLowerCase();
    const lowercaseSubLabel = item.subLabel?.toLowerCase() ?? '';

    return lowercaseLabel.includes(lowercaseSearchTerm) || lowercaseSubLabel.includes(lowercaseSearchTerm);
  });
}
