import { Pipe, PipeTransform } from '@angular/core';

import { GraduationValueType } from '@core/shared/domain';

import {
  GraduationValue,
  GraduationValueAmount,
  GraduationValueDuration,
  GraduationValueLogistic,
  GraduationValuePercent,
  GraduationValuePrice,
} from '../models';

interface BaseUnitInfo {
  /**
   * Base unit
   */
  baseUnit: string;

  /**
   * Long name of the base unit, if mapped
   */
  baseUnitLongName?: string;

  /**
   * Quantity of the base unit
   */
  quantityOfBaseUnit: number;
}

@Pipe({ name: 'mpGraduationValueOutput', standalone: true })
export class GraduationValueOutputPipe implements PipeTransform {
  transform(graduationValue: GraduationValue, baseUnitInfo?: BaseUnitInfo): string {
    switch (graduationValue.type) {
      case GraduationValueType.Logistic:
        return this.getLogisticOutput(graduationValue, baseUnitInfo);
      case GraduationValueType.Amount:
        return this.getAmountOutput(graduationValue);
      case GraduationValueType.Percent:
        return this.getPercentOutput(graduationValue);
      case GraduationValueType.Duration:
        return this.getDurationOutput(graduationValue);
      case GraduationValueType.Price:
        return this.getPriceOutput(graduationValue);

      default:
        return '';
    }
  }

  private getLogisticOutput(graduationValue: GraduationValueLogistic, baseUnitInfo?: BaseUnitInfo): string {
    const unitString = `${graduationValue.quantity} ${graduationValue.unit} ${graduationValue.unitLongName ?? ''}`;
    const baseUnitString = baseUnitInfo
      ? `(${baseUnitInfo.quantityOfBaseUnit} ${baseUnitInfo.baseUnit} ${baseUnitInfo.baseUnitLongName ?? ''})`
      : '';

    return `${unitString} ${baseUnitString}`;
  }

  private getAmountOutput(graduationValue: GraduationValueAmount): string {
    return `${graduationValue.value}`;
  }

  private getPercentOutput(graduationValue: GraduationValuePercent): string {
    const percentString = graduationValue.value.toLocaleString('de-DE', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return `${percentString}`;
  }

  private getDurationOutput(graduationValue: GraduationValueDuration): string {
    return `${graduationValue.value} ${graduationValue.unit}`;
  }

  private getPriceOutput(graduationValue: GraduationValuePrice): string {
    const price = graduationValue.price.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${price} ${graduationValue.currency}`;
  }
}
