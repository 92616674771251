import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { Graduation } from '../../models';
import { GraduationTableComponent } from '../graduation-table/graduation-table.component';

@Component({
  selector: 'mp-graduations',
  standalone: true,
  templateUrl: './graduations.component.html',
  styleUrl: './graduations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GraduationTableComponent],
})
export class GraduationsComponent {
  readonly graduations: InputSignal<Graduation[]> = input.required<Graduation[]>();

  readonly showDescriptionColumn: InputSignal<boolean> = input<boolean>(false);

  readonly showEffectColumn: InputSignal<boolean> = input<boolean>(false);

  readonly isFutureCondition: InputSignal<boolean> = input<boolean>(false);
}
