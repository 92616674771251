import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-redirect-link',
  standalone: true,
  templateUrl: './redirect-link.component.html',
  styleUrl: './redirect-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class RedirectLinkComponent {
  readonly linkLabel: InputSignal<string> = input.required<string>();

  readonly redirectLink: InputSignal<string | undefined> = input.required<string | undefined>();
}
