import { NgModule } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';

import { UnsavedChangesHandlerService } from './services';

@NgModule({
  imports: [MatLegacyDialogModule, ConfirmDialogModule],
  providers: [UnsavedChangesHandlerService],
})
export class ConfirmUnsavedChangesModule {}
