import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { first } from 'rxjs';

import { CustomOverlayConfig } from '../custom-overlay/custom-overlay-config';
import { CustomOverlayService } from '../custom-overlay/custom-overlay.service';

import { ComponentFlyoutRef } from './component-flyout-ref';
import { FlyoutComponent } from './flyout-component';

@Injectable({ providedIn: 'root' })
export class ComponentFlyoutService extends CustomOverlayService {
  private currentlyOpenedRef?: ComponentFlyoutRef<FlyoutComponent<unknown>>;

  constructor(injector: Injector) {
    super(injector);
  }

  open<T extends FlyoutComponent<unknown>>(
    component: ComponentType<T>,
    flyoutConfig: CustomOverlayConfig,
    parentInjector?: Injector,
  ): ComponentFlyoutRef<T> {
    const overlayRef = this.createOverlay(flyoutConfig);
    const flyoutRef = new ComponentFlyoutRef<T>(overlayRef);

    const overlayComponentRef = this.attachOverlayContainer(
      component,
      overlayRef,
      flyoutConfig,
      flyoutRef,
      parentInjector,
    );

    flyoutRef.initializeWithComponentRef(overlayComponentRef);

    if (flyoutConfig.hasBackdrop) {
      overlayRef.backdropClick().subscribe({
        next: () => {
          flyoutRef.close();
        },
      });
    }

    this.currentlyOpenedRef = flyoutRef;
    flyoutRef.afterClosed$.pipe(first()).subscribe({ next: () => (this.currentlyOpenedRef = undefined) });

    return flyoutRef;
  }

  protected override createOverlay(flyoutConfig: CustomOverlayConfig): OverlayRef {
    const overlayConfig = this.buildOverlayConfig(flyoutConfig);

    return this.overlay.create(overlayConfig);
  }

  private buildOverlayConfig(flyoutConfig: CustomOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().right().top();

    return {
      panelClass: 'mp-flyout',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,

      ...flyoutConfig.overlayConfig,
    };
  }

  isCurrentlyOpened(): boolean {
    return !!this.currentlyOpenedRef?.componentInstance;
  }
}
