import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, ModelSignal, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'mp-global-article-toggle',
  standalone: true,
  templateUrl: './global-article-toggle.component.html',
  styleUrl: './global-article-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, MatIconModule],
})
export class GlobalArticleToggleComponent {
  @HostBinding() readonly class = 'mpcm-global-article-toggle';

  readonly checked: ModelSignal<boolean> = model.required<boolean>();
  readonly disabled: InputSignal<boolean> = input.required<boolean>();

  onToggleChange(checked: boolean): void {
    this.checked.set(checked);
  }
}
