import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  Optional,
  OutputEmitterRef,
  Self,
  Signal,
  TemplateRef,
  ViewChild,
  computed,
  input,
  model,
  output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
// This is one of the few places where we allow this import
// eslint-disable-next-line no-restricted-imports
import { MatLegacySelect, MatLegacySelectModule } from '@angular/material/legacy-select';

import { TemplateContext, TypedTemplateDirective } from '@core/shared/util';

import { OptionComponent, SelectOption, SelectedOptionComponent } from '../option';

import { FormFieldCustomSelectControlComponent } from './form-field-custom-select-control.component';

export type CompareWithFunction<T> = (o1: T, o2: T) => boolean;

export interface SelectOptionTemplateContext<OptionValueType>
  extends TemplateContext<SelectOption<OptionValueType> | undefined> {
  isActive: boolean;
}

export type SelectOptionTemplateRef<OptionValueType> = TemplateRef<SelectOptionTemplateContext<OptionValueType>>;

@Component({
  selector: 'mp-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgTemplateOutlet,
    FormsModule,

    MatLegacySelectModule,

    OptionComponent,
    SelectedOptionComponent,
    TypedTemplateDirective,
  ],
  providers: [{ provide: MatLegacyFormFieldControl, useExisting: SelectComponent }],
})
export class SelectComponent<T> extends FormFieldCustomSelectControlComponent<T> implements ControlValueAccessor {
  @HostBinding() readonly class = 'mp-select';

  @HostBinding() override id = `mp-select-${FormFieldCustomSelectControlComponent.nextId++}`;

  @ViewChild('selectControl', { static: true }) protected readonly selectControl!: MatLegacySelect;

  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly selectedValue: ModelSignal<T | undefined> = model<T | undefined>();

  readonly isRequired: InputSignal<boolean> = input<boolean>(false, { alias: 'required' });
  readonly isDisabled: InputSignal<boolean> = input<boolean>(false, { alias: 'disabled' });

  readonly inputPlaceholder: InputSignal<string | undefined> = input<string | undefined>(undefined, {
    alias: 'placeholder',
  });

  readonly selectedOptionTemplate: InputSignal<SelectOptionTemplateRef<T> | undefined> =
    input<SelectOptionTemplateRef<T>>();

  readonly optionTemplate: InputSignal<SelectOptionTemplateRef<T> | undefined> = input<SelectOptionTemplateRef<T>>();

  readonly emptyOptionsListTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();

  readonly customCompareWithFunction: InputSignal<CompareWithFunction<T> | undefined> = input<
    CompareWithFunction<T> | undefined
  >();

  readonly openChange: OutputEmitterRef<boolean> = output<boolean>();

  protected readonly selectedOption: Signal<SelectOption<T> | undefined> = computed<SelectOption<T> | undefined>(() =>
    this.options().find(({ value }) => this.selectedValue() === value),
  );

  protected readonly compareWithFunction: Signal<CompareWithFunction<T>> = computed<CompareWithFunction<T>>(
    () => this.customCompareWithFunction() ?? this.defaultCompareWithFunction,
  );

  readonly optionTemplateContextType!: SelectOptionTemplateContext<T>;

  private readonly defaultCompareWithFunction: CompareWithFunction<T> = (o1: T, o2: T) => o1 === o2;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() @Self() public override ngControl: NgControl,
  ) {
    super(ngControl);

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  onOpenedChange(isOpened: boolean): void {
    this.openChange.emit(isOpened);
  }

  onSelectionChange(): void {
    this.onChange(this.selectedValue());
  }

  writeValue(selection: T | undefined): void {
    this.selectedValue.set(selection);
    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  onChange = (_value: T | undefined) => {};

  onTouched = () => {};

  registerOnChange(onChange: (value: T | undefined) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
}
