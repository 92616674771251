import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent extends ElementDirective {
  constructor() {
    super();
    this.class = 'mp-spinner';
  }
}
