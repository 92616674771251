import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'mp-view-column, [mpViewColumn]',
  exportAs: 'viewColumn',
  standalone: true,
})
export class ViewColumnDirective {
  @HostBinding('class') protected class: string;

  constructor() {
    this.class = 'page-column';
  }
}
