import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuItem as MatMenuItem, MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ViewportService } from '@core/shared/util';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    AsyncPipe,
    DecimalPipe,

    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
  ],
})
export class OverviewComponent extends ElementDirective implements AfterContentInit {
  @ViewChild('searchField') searchField?: ElementRef;
  @ContentChildren(MatMenuItem) contentChildren!: QueryList<MatMenuItem>;

  @Input()
  @HostBinding('class.mp-overview--custom')
  isCustomHeader = false;

  @Input() moduleTitle?: string;

  public get resultCount(): number | null {
    return this._resultCount;
  }

  @Input()
  public set resultCount(value: NumberInput) {
    this._resultCount = coerceNumberProperty(value, null);
  }

  private _resultCount: number | null = null;

  @Output() readonly search = new EventEmitter<string>();

  isSearchVisible?: boolean;

  isSmallViewport$ = this.viewportService.isSmallViewport$;

  contextMenuButtonVisible = true;

  private readonly searchTerm$ = new Subject<string>();

  constructor(
    private viewportService: ViewportService,
    public route: ActivatedRoute,
  ) {
    super();
    this.class = 'mp-overview';

    this.setupSearchbarListener();
  }

  private setupSearchbarListener(): void {
    this.searchTerm$
      .pipe(
        debounceTime(500),
        map((term: string) => term.trim()),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (term) => {
          this.search.emit(term);
        },
      });
  }

  ngAfterContentInit(): void {
    if (this.contentChildren.length === 0) {
      this.contextMenuButtonVisible = false;
    }
  }

  emitSearchTerm(term: string): void {
    this.searchTerm$.next(term);
  }

  clearSearch(): void {
    if (!this.searchField?.nativeElement) {
      throw Error('"searchField" is undefined!');
    }

    this.searchField.nativeElement.value = '';
    this.searchTerm$.next('');
  }

  toggleSearch(): void {
    this.isSearchVisible = !this.isSearchVisible;

    if (this.isSearchVisible) {
      requestAnimationFrame(() => this.searchField?.nativeElement?.focus());
    }
  }
}
