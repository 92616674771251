import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mpcm-global-article-icon',
  standalone: true,
  template: '<mat-icon [title]="title()" svgIcon="global_article_star" />',
  styleUrls: ['./global-article-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class GlobalArticleIconComponent {
  readonly title: InputSignal<string> = input<string>('aus Globalartikel ergänzt');
}
