<a
  class="link"
  matRipple
  routerLinkActive="active"
  [routerLink]="[path()]"
  [class.link--highlighted]="isHighlighted()"
  [class.link--with-context-actions]="contextMenuButtonVisible()"
>
  <div class="content">
    <ng-content />
  </div>
</a>

<div class="context">
  @if (contextMenuButtonVisible()) {
    <button mat-icon-button color="accent" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  }

  <mat-menu #menu="matMenu">
    <ng-content select="[mat-menu-item]" />
  </mat-menu>
</div>
