<mat-card class="dropdown-card">
  @if (icon(); as icon) {
    <mat-icon class="dropdown-card__icon material-icons-outlined" [mpIcon]="icon" />
  }

  <div class="dropdown-card__title truncate" [title]="title()">
    {{ title() }}
  </div>

  <mp-select
    #selector
    class="dropdown-card__selection"
    [(selectedValue)]="selectedValue"
    [options]="logicalChoiceOptions"
    [selectedOptionTemplate]="selectedOptionTemplate"
    (selectedValueChange)="onSelectionChange($event)"
  >
    <ng-template
      #selectedOptionTemplate
      mpTypedTemplate
      [contextType]="selector.optionTemplateContextType"
      let-selectedOption
    >
      <span class="dropdown-card__selected-option">{{ selectedOption?.label }}</span>
    </ng-template>
  </mp-select>
</mat-card>
