import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { SelectOption } from '../models';

@Component({
  selector: 'mp-selected-option',
  standalone: true,
  template: '<span class="font-bold">{{ selectedOption()?.label }}</span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedOptionComponent {
  readonly selectedOption: InputSignal<SelectOption<unknown> | undefined> = input.required<
    SelectOption<unknown> | undefined
  >();
}
