import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

@Component({
  selector: 'mp-expand-chip',
  standalone: true,
  templateUrl: './expand-chip.component.html',
  styleUrl: './expand-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandChipComponent {
  readonly useSmallChips: InputSignal<boolean> = input<boolean>(false);
  readonly isListExpanded: InputSignal<boolean> = input<boolean>(true);
  readonly numberOfHiddenElements: InputSignal<number> = input.required<number>();

  readonly expandList: OutputEmitterRef<void> = output<void>();

  onClick(): void {
    this.expandList.emit();
  }
}
