import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { UtilPipesModule } from '@core/shared/util';

import { SearchFieldComponent } from '../../search-field/search-field.component';
import { SearchFieldModule } from '../../search-field/search-field.module';

@Component({
  selector: 'mp-entity-list-head',
  standalone: true,
  templateUrl: './entity-list-head.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatLegacyButtonModule, SearchFieldModule, UtilPipesModule],
})
export class EntityListHeadComponent {
  @HostBinding('class') readonly class = 'mp-entity-list-head';
  @ViewChild(SearchFieldComponent) searchField?: SearchFieldComponent;

  @Input() searchTerm = '';

  @Input()
  get showSearch() {
    return this._showSearch;
  }
  set showSearch(value: BooleanInput) {
    this._showSearch = coerceBooleanProperty(value);
  }
  private _showSearch = true;

  @Input()
  get editMode() {
    return this._editMode;
  }
  set editMode(value: BooleanInput) {
    this._editMode = coerceBooleanProperty(value);
  }
  private _editMode = true;

  @Input() count = 0;
  @Input() genericEntityName: [string, string] = ['Ergebnis', '-se'];

  @Output() readonly add = new EventEmitter<void>();
  @Output() readonly searched = new EventEmitter<string | undefined>();

  addClicked() {
    this.add.emit();
  }

  search(searchTerm: string): void {
    this.searchField?.search(searchTerm);
  }

  onSearchCleared(): void {
    this.searchField?.clear();
    this.searched.emit('');
  }

  onSearchTermEmitted(searchTerm: string): void {
    const nextValue = searchTerm.trim().length > 0 ? searchTerm : undefined;
    this.searched.emit(nextValue);
  }
}
