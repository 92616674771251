<div class="wrapper">
  <div class="search-field-col">
    <mat-form-field appearance="outline" size="small">
      <input
        matInput
        type="text"
        [placeholder]="placeholder"
        [formControl]="searchField"
        (keyup.enter)="emitSearchEvent()"
      />

      <!-- NOTE: Order is important for these <ng-content> tags! -->
      <ng-container matSuffix>
        <ng-content select="[mpSearchFieldButton][mpSearchFieldButton=right]" />
      </ng-container>

      <ng-container matPrefix>
        <ng-content select="[mpSearchFieldButton][mpSearchFieldButton=left]" />
        <ng-content select="[mpSearchFieldButton]" />
      </ng-container>
    </mat-form-field>
  </div>
</div>
