export enum InsightsBaseEvent {
  FILTERS_EXPAND = 'Filterleiste ausklappen',
  FILTERS_COLLAPSE = 'Filterliste einklappen',

  FILTER_EXPAND = 'Filter ausklappen',
  FILTER_COLLAPSE = 'Filter einklappen',
  FILTER_CHANGE = 'Filter nutzen',
  FILTER_DATE_CHANGE = 'Filter Datum nutzen',
  FILTER_SEARCH = 'Filter Suche nutzen',
  FILTER_RESET = 'Filter zurücksetzen',

  VALUE_COPY = 'Wert kopieren',

  TAB_CHANGE = 'Tab Wechsel',

  PAGINATION_CHANGE = 'Paging nutzen',
}

export type InsightsEvent = string | InsightsBaseEvent;

export function insightsNamedEvent(name: string, event: InsightsBaseEvent): InsightsEvent {
  return `${name} ${event}`;
}
