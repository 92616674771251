<div class="graduations">
  @if (graduations()?.length) {
    @for (graduation of graduations(); track $index; let isFirst = $first) {
      <mp-graduation-table
        [graduation]="graduation"
        [isFirst]="isFirst"
        [showDescriptionColumn]="showDescriptionColumn()"
        [showEffectColumn]="showEffectColumn()"
        [isFutureCondition]="isFutureCondition()"
      />
    }
  } @else {
    <p class="graduations__no-data">Keine Staffelungen vorhanden</p>
  }
</div>
