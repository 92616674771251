import { SelectionChange } from '@angular/cdk/collections';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ComponentFlyoutRef } from '../component/component-flyout-ref';

import { SelectorComponent } from './component/selector.component';
import { SelectionItem } from './selection-item/selection-item';

// TODO: Check if UnwrapIfNeeded was used correctly here:
type UnwrapIfNeeded<T> = T extends SelectionItem<infer U> ? U : T;

export class SelectorFlyoutRef<T> extends ComponentFlyoutRef<SelectorComponent<UnwrapIfNeeded<T>>> {
  private readonly _onSelected$ = new Subject<SelectionChange<T>>();
  readonly onSelected$ = this._onSelected$.asObservable();

  private readonly __afterClosed$ = new Subject<Array<UnwrapIfNeeded<T>>>();
  override readonly afterClosed$ = this.__afterClosed$.asObservable();

  private _selectorInstance?: SelectorComponent<UnwrapIfNeeded<T>>;
  override set componentInstance(instance: SelectorComponent<UnwrapIfNeeded<T>> | undefined) {
    this._selectorInstance = instance;

    if (instance) {
      this.setupAfterClosedSubject(instance);
      this.setupOnSelectedSubject(instance);
    }
  }
  override get componentInstance(): SelectorComponent<UnwrapIfNeeded<T>> | undefined {
    return this._selectorInstance;
  }

  /* This constructor is needed for Angulars dependency injection */
  constructor(overlayRef: OverlayRef) {
    super(overlayRef);
  }

  private setupAfterClosedSubject(instance: SelectorComponent<UnwrapIfNeeded<T>>): void {
    instance.afterClosed$.pipe(take(1)).subscribe({
      next: (returnValue) => {
        this.disposeAndFreeFromMemory();

        const selection = this.unwrapSelectionIfNeeded(returnValue) as Array<T extends SelectionItem<infer U> ? U : T>;
        this.__afterClosed$.next(selection);

        this.__afterClosed$.complete();
        this._onSelected$.complete();
      },
    });
  }

  private setupOnSelectedSubject(instance: SelectorComponent<UnwrapIfNeeded<T>>): void {
    instance.selectionChange$.pipe().subscribe({
      next: (selection) => {
        this._onSelected$.next(selection as any);
      },
    });
  }

  private unwrapSelectionIfNeeded<T>(selection: T | Array<T>) {
    const isArrayOfDefaultSelectionItems =
      Array.isArray(selection) && selection[0] && selection[0] instanceof SelectionItem;

    const result = isArrayOfDefaultSelectionItems
      ? SelectionItem.unwrapArray(selection as unknown as Array<SelectionItem<T>>)
      : selection;

    return result;
  }
}
