<mat-autocomplete
  #autocompletePanel="matAutocomplete"
  [class]="[class, panelClass()]"
  [displayWith]="displayFn"
  [autoActiveFirstOption]="autoActiveFirstOption()"
  [requireSelection]="true"
  (optionSelected)="emitSelectedValue($event.option.value)"
>
  @if (emptyOptionsListTemplate() && !options().length && !hideEmptyOptionsList()) {
    <mat-option>
      <ng-container [ngTemplateOutlet]="emptyOptionsListTemplate()!" />
    </mat-option>
  }

  @if (isVirtualScrollEnabled()) {
    <cdk-virtual-scroll-viewport class="virtual-scroll-container" [itemSize]="virtualScrollItemSize()">
      <ng-container *cdkVirtualFor="let option of options(); trackBy: trackByFn">
        <ng-container *ngTemplateOutlet="optionView.template; context: { $implicit: option }" />
      </ng-container>
    </cdk-virtual-scroll-viewport>
  } @else {
    @for (option of options(); track option.value) {
      <ng-container *ngTemplateOutlet="optionView.template; context: { $implicit: option }" />
    }
  }

  <ng-template
    #optionView="mpTypedTemplate"
    mpTypedTemplate
    [contextType]="optionWrapperTemplateContextType"
    let-option
  >
    <mat-option #matOption [value]="option.value" [disabled]="!!option.disabled">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate() ?? defaultOption.template;
          context: { $implicit: option, isActive: matOption.active }
        "
      />
    </mat-option>
  </ng-template>
</mat-autocomplete>

<ng-template #defaultOption="mpTypedTemplate" mpTypedTemplate [contextType]="optionTemplateContextType" let-option>
  <mp-option [option]="option" />
</ng-template>
