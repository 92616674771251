@if (!hideLabel) {
  <span class="label truncate">COVIN-konform:</span>
}
<span
  class="ico"
  [ngClass]="{
    'ico--red': _value <= 0.3,
    'ico--yellow': _value > 0.3 && _value <= 0.7,
    'ico--green': _value > 0.7
  }"
  title="COVIN-konform"
>
</span>
<span class="val" title="COVIN-konform">{{ _value | percent: '1.0-2' }}</span>
@if (_currentValue && _value !== _currentValue) {
  <span
    class="diff"
    [ngClass]="{
      'diff--red': _currentValue > _value,
      'diff--green': _currentValue <= _value
    }"
  >
    {{ math.abs(_value - _currentValue) | percent: '1.0-2' }}
  </span>
}
