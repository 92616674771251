<div class="graduation-effect">
  @if (graduationLevel().isSignedCondition) {
    <mat-icon class="graduation-effect__signed-icon" svgIcon="price_signed" title="Gezeichnet" />
  }

  <span
    *ngrxLet="graduationLevel().effect! | mpGraduationValueOutput as price"
    class="graduation-effect__price truncate"
    [ngClass]="{
      'graduation-effect__price--current': !isFutureCondition(),
      'graduation-effect__price--future': isFutureCondition()
    }"
    [title]="price"
  >
    <span>{{ price }}</span>
  </span>

  @if (showValidityDatesTooltip() && hasPriceValidityDates()) {
    <mat-icon
      class="graduation-effect__price-validity-dates-icon material-icons-outlined"
      [matTooltip]="graduationLevel().effect! | mpPriceValidityDates"
      matTooltipPosition="above"
      matTooltipClass="mp-graduation-effect__price-validity-dates-tooltip"
    >
      info
    </mat-icon>
  }
</div>
