import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { QuillEditorBase } from 'ngx-quill';
import type Quill from 'quill';
import type Toolbar from 'quill/modules/toolbar';
import { Observable, first, map, tap } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { SpinnerComponent } from '../spinner/spinner.component';

import { richTextEditorImageHandler } from './rich-text-editor-image-handler';
import { richTextEditorLinkHandler } from './rich-text-editor-link-handler';
import { RichTextEditorToolbarComponent } from './rich-text-editor-toolbar/rich-text-editor-toolbar.component';

@Component({
  selector: 'mp-rich-text-editor',
  standalone: true,
  templateUrl: './rich-text-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LetDirective, NgClass, SpinnerComponent, RichTextEditorToolbarComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
})
export class RichTextEditorComponent extends QuillEditorBase implements ControlValueAccessor, Validator {
  @HostBinding('class') readonly class = 'mp-rich-text-editor';

  readonly editorLoaded$: Observable<boolean> = this.onEditorCreated.pipe(
    tap(() => this.addHandlersToEditor()),
    map(() => true),
    first(),
    startWith(false),
  );

  private addHandlersToEditor(): void {
    const quillEditor: Quill = this.quillEditor;
    const toolbarModule: Toolbar = quillEditor.getModule('toolbar') as Toolbar;

    toolbarModule.addHandler('image', () => richTextEditorImageHandler(quillEditor));
    toolbarModule.addHandler('link', () => richTextEditorLinkHandler(quillEditor));
  }
}
