<nav class="navigation-tabs-menu" mat-tab-nav-bar [tabPanel]="tabPanel">
  <a
    *ngFor="let tabLink of tabLinks; trackBy: trackByFn"
    mat-tab-link
    [routerLink]="tabLink.link"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive"
    >{{ tabLink.label }}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel>
  <div class="navigation-tab-content">
    <ng-content></ng-content>
  </div>
</mat-tab-nav-panel>
