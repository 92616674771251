import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mp-info-card',
  standalone: true,
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {}
