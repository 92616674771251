<mat-card class="filter-input-card mp-filter-input-options-flat">
  <mat-accordion class="card-content" multi>
    @for (section of filterSections; track section) {
      <mat-expansion-panel
        [expanded]="isFilterSectionExpanded(section)"
        (expandedChange)="setFilterPanelExpansionState(section, $event)"
        [@.disabled]="true"
      >
        <mat-expansion-panel-header>
          <div class="filter-panel-info--wrapper">
            <!-- Icon -->
            @if (section.icon) {
              <div class="filter-input-icon">
                <mat-icon class="material-icons-outlined">
                  {{ section.icon }}
                </mat-icon>
              </div>
            }
            <!-- Title -->
            <div class="filter-input-title">
              <span>{{ section.label }}</span>
            </div>
          </div>
          @if (collapseSectionsState[section.key] && section.searchable) {
            <button
              mat-icon-button
              class="search-button"
              aria-label="Toggle Search"
              (click)="$event.stopPropagation(); toggleSearchInput(section.key)"
            >
              <mat-icon>{{ !showSearchSectionsState[section.key] ? 'search' : 'close' }}</mat-icon>
            </button>
          }
          @if (displayStatistics) {
            <div class="filter-panel-statistics--wrapper">
              @if (!collapseSectionsState[section.key] && collapseSectionsSelectedDataInfo[section.key]) {
                <div class="filter-input-stats">
                  @if (collapseSectionsSelectedDataInfo[section.key].isRadio) {
                    @if (collapseSectionsSelectedDataInfo[section.key].icon) {
                      <mat-icon class="material-icons-outlined">
                        {{ collapseSectionsSelectedDataInfo[section.key].icon }}
                      </mat-icon>
                    }
                    <span>
                      {{ collapseSectionsSelectedDataInfo[section.key].value }}
                    </span>
                  } @else {
                    <mp-filter-badge [value]="collapseSectionsSelectedDataInfo[section.key].value" />
                  }
                </div>
              }
            </div>
          }
        </mat-expansion-panel-header>

        <div class="filter-section-body">
          <mp-filter-item-selector
            showToggleAll
            searchFieldAppearance="flat"
            [name]="section.key + ''"
            [items]="section.options"
            [itemStats]="filterStats[section.key]"
            [itemIcons]="filterIcons[section.key]"
            [searchable]="showSearchSectionsState[section.key]"
            [multiselect]="section.multiselect ?? true"
            [maxVisibleItemCount]="section.data?.maxVisibleItemCount ?? 'all'"
            [showExpandAll]="section.data?.showExpandAll ?? false"
            [showToggleAll]="section.data?.showToggleAll ?? true"
            [sortedBySelection]="section.data?.sortedBySelection ?? false"
            [showStatusbar]="false"
            [genericOptionName]="section.data?.genericOptionName ?? ['Merkmal', '-e']"
            [searchFieldText]="section.data?.searchFieldText ?? 'Merkmale durchsuchen'"
            [hasBeenExpanded]="hasBeenExpanded[section.key] ?? false"
            (valueChanges)="emitValueChanges($event, section)"
            (filterExpanded)="onFilterExpanded($event, section.key)"
            (valueSelect)="section.data?.onValueSelect?.($event)"
          />
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
</mat-card>
