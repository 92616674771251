import { LayoutModule } from '@angular/cdk/layout';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  InputSignal,
  ModelSignal,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  input,
  model,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { FeatureFlags, FeatureFlagsHandlerService, RouterEventsService, ViewportService } from '@core/shared/util';

import { AppHeaderComponent } from '../app-header';

@Component({
  selector: 'mp-shell',
  standalone: true,
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LetDirective,
    RouterModule,
    LayoutModule,

    MatToolbarModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,

    AppHeaderComponent,
  ],
})
export class ShellComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-shell';

  readonly appTitle: InputSignal<string> = input.required<string>();

  readonly isOpened: ModelSignal<boolean> = model.required<boolean>();

  readonly showAppLogo: InputSignal<boolean> = input.required<boolean>();

  readonly isLoading: WritableSignal<boolean> = signal<boolean>(false);

  protected readonly isMediumViewport: Signal<boolean> = toSignal(this.viewportService.isMediumViewport$, {
    requireSync: true,
  });

  protected readonly sidenavMode: Signal<MatDrawerMode> = computed(() => (this.isMediumViewport() ? 'over' : 'side'));

  readonly featureFlags$: Observable<FeatureFlags> = this.featureFlagsHandlerService.featureFlags$;

  constructor(
    private readonly viewportService: ViewportService,
    private readonly routerEventsService: RouterEventsService,
    private readonly featureFlagsHandlerService: FeatureFlagsHandlerService,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.routerEventsService.navigationStart$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isLoading.set(true);
    });

    this.routerEventsService.navigationEndCancelError$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isLoading.set(false);
    });
  }
}
