import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  InputSignal,
  ViewChild,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-nav-item',
  standalone: true,
  templateUrl: './navigation-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RouterModule, MatIconModule, MatLegacyRippleModule],
})
export class NavigationItemComponent extends ElementDirective {
  @HostBinding('class') override readonly class = 'mp-nav-item';

  @HostBinding('attr.aria-expanded')
  isExpanded = false;
  private readonly mouseEvents$ = new Subject<boolean>();

  readonly icon: InputSignal<string> = input.required<string>();
  readonly path: InputSignal<string> = input.required<string>();
  readonly label: InputSignal<string> = input<string>('');
  readonly isGroup: InputSignal<boolean> = input<boolean>(false);
  readonly addChildItemLine: InputSignal<boolean> = input<boolean>(false);

  @ViewChild('link')
  linkRef!: ElementRef<HTMLAnchorElement>;

  @ViewChild('groupcontent')
  groupContentRef!: ElementRef<HTMLDivElement>;

  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.mouseEvents$.next(true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.mouseEvents$.next(false);
  }

  constructor(public router: Router) {
    super();

    this.mouseEvents$.pipe(debounceTime(50), takeUntilDestroyed()).subscribe((mouseEvent) => {
       this.isExpanded = mouseEvent === true;

      if (this.isGroup()) {
        this.handleSubMenuContainByScreen();
      }
    });
  }

  get isRouteActive(): boolean {
    if (!this.path()) {
      throw Error('"path" not set in template!');
    }

    return this.router.isActive(this.path(), false);
  }

  private handleSubMenuContainByScreen(): void {
    requestAnimationFrame(() => {
      if (this.linkRef.nativeElement && this.groupContentRef.nativeElement) {
        const group = this.groupContentRef.nativeElement;
        const sideNav = this.linkRef.nativeElement.closest('.mat-sidenav');
        if (sideNav && !sideNav.classList.contains('mat-drawer-opened') && this.isExpanded) {
          const link = this.linkRef.nativeElement;

          const linkRect = link.getBoundingClientRect();
          const groupRect = group.getBoundingClientRect();
          const buffer = 10;

          const top =
            linkRect.top + groupRect.height + buffer < window.innerHeight
              ? linkRect.top
              : window.innerHeight - groupRect.height - buffer;

          group.style.top = `${Math.ceil(top)}px`;
          group.style.left = `${Math.ceil(linkRect.right)}px`;
        } else {
          group.style.top = group.style.left = '';
        }
      }
    });
  }
}
