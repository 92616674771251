import { animate, state, style, transition, trigger } from '@angular/animations';

export namespace FlyoutAnimation {
  export type State = '*' | 'open' | 'closed';

  export const AnimationDuration = 0.2 * 1000;

  const Timing = `${AnimationDuration / 1000}s ease-in-out`;
  export const Named = (name: string) => [
    trigger(name, [
      state('open', style({ transform: 'translateX(0%)' })),
      state('closed', style({ transform: 'translateX(100%)' })),

      transition(':enter', [style({ transform: 'translateX(100%)' }), animate(Timing)]),
      transition('open <=> closed', [animate(Timing)]),
    ]),
  ];
}
