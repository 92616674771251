import { Navigation, Scrollbar } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

export interface CarouselElements {
  navigationNext: HTMLElement;
  navigationPrev: HTMLElement;
  navigationDisabledClass: string;
  scrollbar: HTMLElement;
}

export function getCarouselConfig(
  carouselConfigOverrides: SwiperOptions | undefined,
  elements: CarouselElements,
): SwiperOptions {
  const baseCarouselConfig: SwiperOptions = getBaseCarouselConfig(elements);
  const newCarouselConfig: SwiperOptions = carouselConfigOverrides ? carouselConfigOverrides : DEFAULT_CAROUSEL_CONFIG;

  return {
    ...baseCarouselConfig,
    ...newCarouselConfig,
  };
}

function getBaseCarouselConfig({
  navigationNext,
  navigationPrev,
  scrollbar,
  navigationDisabledClass,
}: CarouselElements): SwiperOptions {
  return {
    modules: [Navigation, Scrollbar],
    navigation: {
      nextEl: navigationNext,
      prevEl: navigationPrev,
      disabledClass: navigationDisabledClass,
    },
    scrollbar: {
      el: scrollbar,
      draggable: true,
      hide: false,
    },
  };
}

export const DEFAULT_CAROUSEL_CONFIG: SwiperOptions = {
  slidesPerView: 4,
  slidesPerGroup: 4,
  breakpoints: {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    570: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    900: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1900: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
};
