<div class="image-container" [class]="{ 'image-container--highlighted': isHighlighted() }">
  @if (selectedImage()) {
    @if (!hasError()) {
      @if (overlay()) {
        <div class="click-wrapper" (click)="openDialog()">
          <div class="click-wrapper__elements"><mat-icon class="click-wrapper__icon">loupe</mat-icon> Vergrößern</div>
        </div>
      }
      <img class="article-image" [alt]="alternateText()" [src]="imagePath()" (error)="onErrorWhileLoadingImage()" />
    } @else {
      <div class="placeholder">
        <mat-icon>image</mat-icon>
      </div>
    }
    @if (isHighlighted()) {
      <mpcm-global-article-icon class="global-article-icon" />
    }
  } @else {
    <div class="placeholder">
      <mat-icon class="placeholder__icon"> image </mat-icon>
    </div>
  }
</div>

@if (images().length > 1 && !overrideMultipleImages()) {
  <mp-image-gallery-viewer-controller
    class="viewer-controller"
    [(selectedImageIndex)]="selectedImageIndex"
    [imagesNumber]="images().length"
    (selectedImageIndexChange)="resetError()"
  />
}
