<ng-container *ngrxLet="featureFlags$ as featureFlags">
  <mat-toolbar class="toolbar" [ngClass]="{ 'toolbar--custom': featureFlags.customHeader }">
    <mp-app-header
      [featureFlags]="featureFlags"
      [showAppLogo]="showAppLogo()"
      [appTitle]="appTitle()"
      (sidenavToggle)="sidenav.toggle()"
    />
  </mat-toolbar>

  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate" color="accent" style="position: absolute; top: 0; z-index: 100" />
  }

  <mat-sidenav-container class="navigation-container">
    <mat-sidenav
      #sidenav
      class="sidebar"
      [opened]="isOpened()"
      [mode]="sidenavMode()"
      (openedStart)="isOpened.set(true)"
      (closedStart)="isOpened.set(false)"
    >
      <div class="sidebar-wrapper">
        <nav class="sidebar-wrapper__navigation-elements">
          <ng-content />
          <ng-content select="mp-nav-item" />
        </nav>
        <div class="sidebar-end-wrapper">
          <ng-content select="[sidebar-end-element]" />

          <mat-divider class="sidebar-end-wrapper__divider" />

          <button class="sidebar-end-wrapper__toggle-menu-button" mat-icon-button (click)="sidenav.toggle()">
            <mat-icon class="sidebar-end-wrapper__toggle-menu-icon">menu_open</mat-icon>
            @if (sidenav.opened) {
              <span class="sidebar-end-wrapper__toggle-menu-button--label">Navigation minimieren</span>
            }
          </button>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="content">
      <router-outlet />
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
