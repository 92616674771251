import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mp-list-count-title',
  standalone: true,
  templateUrl: './list-count-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCountTitleComponent {
  @HostBinding('class') readonly class = 'mp-list-count-title';

  readonly count: InputSignal<number> = input.required<number>();
  readonly hideZero: InputSignal<boolean> = input<boolean>(false);
}
