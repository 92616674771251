<ng-container *ngIf="selectedItemsQuantity">
  <span *ngrxLet="selectedItemsQuantity | number as selectedItemsNumber" [title]="selectedItemsNumber">
    {{ selectedItemsNumber }} /
  </span>
</ng-container>

<span
  *ngrxLet="totalItemsQuantity | autoSingularOrPlural: 'Ergebnis' : 'Ergebnisse' as totalItemsInfo"
  [title]="totalItemsInfo"
>
  {{ totalItemsInfo }}
</span>
