import { EntityType } from './entity-type';

export const EntityIcons: Record<EntityType, string> = {
  Organisation: 'account_balance',
  Rolle: 'badge',
  Adresse: 'place',
  Recht: 'verified_user',
  Katalog: 'menu_book',
  Kostenstelle: 'account_balance_wallet',
  Benutzer: 'person',
};
