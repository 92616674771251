<mp-search-field #searchField class="search-bar" [(value)]="searchTerm" (searched)="onSearchTermChange($event)">
  <button mat-icon-button mpSearchFieldButton="left" (click)="searchField.search(searchField.value)">
    <mat-icon>search</mat-icon>
  </button>

  @if (searchTerm().length) {
    <button mat-icon-button mpSearchFieldButton="right" (click)="clearSearchTerm()">
      <mat-icon>clear</mat-icon>
    </button>
  }
</mp-search-field>

<div class="items-list">
  <ng-content />
</div>
