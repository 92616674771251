import { NgFor, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, tap } from 'rxjs';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { CarouselElements, getCarouselConfig } from './carousel-utils';

@Component({
  selector: 'mp-carousel',
  standalone: true,
  templateUrl: './carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgTemplateOutlet, MatIconModule, MatExpansionModule],
})
export class CarouselComponent implements AfterViewInit {
  @HostBinding() readonly class = 'mpms-carousel';

  @Input() set customCarouselConfig(carouselConfig: SwiperOptions) {
    this._carouselConfig$.next(carouselConfig);
  }

  @Input() slidesData: unknown[] = [];

  @ViewChild('swiperContainer') swiperContainer!: ElementRef<HTMLElement>;

  @ViewChild('navigationPrev') navigationPrev!: ElementRef<HTMLElement>;
  @ViewChild('navigationNext') navigationNext!: ElementRef<HTMLElement>;

  @ViewChild('scrollbar') scrollbar!: ElementRef<HTMLElement>;

  @ContentChild(TemplateRef)
  slideTemplate!: TemplateRef<unknown>;

  private readonly _carouselConfig$: BehaviorSubject<SwiperOptions | undefined> = new BehaviorSubject<
    SwiperOptions | undefined
  >(undefined);

  private readonly NAVIGATION_DISABLED_CLASS: string = 'carousel-container__navigation--disabled';

  private swiper: Swiper | undefined = undefined;

  constructor(private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this._carouselConfig$
      .pipe(
        tap((carouselConfig) => this.initCarousel(carouselConfig)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  trackByFn(index: number): number {
    return index;
  }

  private initCarousel(carouselConfig: SwiperOptions | undefined) {
    if (this.swiper) this.swiper.destroy();
    this.swiper = new Swiper(
      this.swiperContainer.nativeElement,
      getCarouselConfig(carouselConfig, this.getCarouselElements()),
    );
  }

  private getCarouselElements(): CarouselElements {
    return {
      navigationDisabledClass: this.NAVIGATION_DISABLED_CLASS,
      navigationNext: this.navigationNext.nativeElement,
      navigationPrev: this.navigationPrev.nativeElement,
      scrollbar: this.scrollbar.nativeElement,
    };
  }
}
