import { SelectionItem, SelectionItemWrapper, WrappingOptions } from './selection-item';

type Stringifier<T> = (entity: T) => string;
type OptionalStringifier<T> = (entity: T) => string | undefined;

export class SelectionItemBuilder {
  private constructor() {}

  static fromItem<T>(item: T): SelectionItemSingleBuilder<T> {
    return new SelectionItemSingleBuilder<T>(item);
  }

  static fromArray<T>(array: Array<T>): SelectionItemArrayBuilder<T> {
    return new SelectionItemArrayBuilder<T>(array);
  }
}

abstract class BaseSelectionItemBuilder<T> {
  protected _wrapper: SelectionItemWrapper<T> = {
    header: String,
    subheader: () => undefined,
  };
}

class SelectionItemSingleBuilder<T> extends BaseSelectionItemBuilder<T> {
  constructor(private readonly item: T) {
    super();
  }

  build(): SelectionItem<T> {
    return SelectionItem.wrap(this.item, this._wrapper);
  }

  header(stringifier: keyof T | Stringifier<T>): SelectionItemSingleBuilder<T> {
    this._wrapper.header = stringifier;

    return this;
  }

  subheader(stringifier: keyof T | OptionalStringifier<T>): SelectionItemSingleBuilder<T> {
    this._wrapper.subheader = stringifier;

    return this;
  }

  wrapper(itemWrapper: SelectionItemWrapper<T>): SelectionItemSingleBuilder<T> {
    this._wrapper = itemWrapper;

    return this;
  }
}

class SelectionItemArrayBuilder<T> extends BaseSelectionItemBuilder<T> {
  private _options: WrappingOptions<T> = {};

  constructor(private readonly items: Array<T>) {
    super();
  }

  build(): Array<SelectionItem<T>> {
    return SelectionItem.wrapArray(this.items, this._wrapper, this._options);
  }

  header(stringifier: keyof T | Stringifier<T>): SelectionItemArrayBuilder<T> {
    this._wrapper.header = stringifier;

    return this;
  }

  subheader(stringifier: keyof T | OptionalStringifier<T>): SelectionItemArrayBuilder<T> {
    this._wrapper.subheader = stringifier;

    return this;
  }

  wrapper(itemWrapper: SelectionItemWrapper<T>): SelectionItemArrayBuilder<T> {
    this._wrapper = itemWrapper;

    return this;
  }

  options(options: WrappingOptions<T>): SelectionItemArrayBuilder<T> {
    this._options = options;

    return this;
  }

  exclude(exclude: (entity: T) => boolean): SelectionItemArrayBuilder<T> {
    this._options.exclude = exclude;

    return this;
  }
}
