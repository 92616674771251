import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { GraduationValueOutputPipe } from '../../../graduations';
import { ParamWrapperComponent } from '../../../param-wrapper/param-wrapper.component';
import { AgreementBaseInfo } from '../../models';

@Component({
  selector: 'mp-agreement-info-list',
  standalone: true,
  templateUrl: './agreement-info-list.component.html',
  styleUrl: './agreement-info-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, ParamWrapperComponent, GraduationValueOutputPipe],
})
export class AgreementInfoListComponent {
  readonly agreementBaseInfo: InputSignal<AgreementBaseInfo> = input.required<AgreementBaseInfo>();
}
