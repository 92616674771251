import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  GlobalPositionStrategy,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { CustomOverlayConfig } from '../flyout/custom-overlay/custom-overlay-config';
import { CustomOverlayRef } from '../flyout/custom-overlay/custom-overlay-ref';
import { CustomOverlayService } from '../flyout/custom-overlay/custom-overlay.service';

import { TooltipContentComponent, TooltipOverlayData } from './tooltip-content/tooltip-content.component';

const TOOLTIP_CONTAINER_CLASS = 'mp-tooltip-container';

@Injectable()
export class TooltipOverlayService extends CustomOverlayService {
  readonly TOOLTIP_DEFAULT_POSITION: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -24,
  };

  private readonly TOOLTIP_PREFERRED_POSITIONS: ConnectedPosition[] = [
    this.TOOLTIP_DEFAULT_POSITION,
    { ...this.TOOLTIP_DEFAULT_POSITION, originY: 'bottom', overlayY: 'top', offsetY: 24 },
  ];

  constructor(injector: Injector) {
    super(injector);
  }

  open(
    component: ComponentType<TooltipContentComponent>,
    tooltipConfig: CustomOverlayConfig<TooltipOverlayData>,
    connectedTo?: FlexibleConnectedPositionStrategyOrigin,
  ): CustomOverlayRef<TooltipContentComponent> {
    const overlayRef: OverlayRef = this.createOverlay(tooltipConfig, connectedTo);
    const customOverlayRef: CustomOverlayRef<TooltipContentComponent> = new CustomOverlayRef<TooltipContentComponent>(
      overlayRef,
    );

    const overlayComponentRef: ComponentRef<TooltipContentComponent> = this.attachOverlayContainer(
      component,
      overlayRef,
      tooltipConfig,
      customOverlayRef,
    );

    customOverlayRef.initializeWithComponentRef(overlayComponentRef);

    return customOverlayRef;
  }

  protected override createOverlay(
    tooltipConfig: CustomOverlayConfig<TooltipOverlayData>,
    connectedTo?: FlexibleConnectedPositionStrategyOrigin,
  ): OverlayRef {
    const overlayConfig: OverlayConfig = this.buildOverlayConfig(tooltipConfig, connectedTo);

    return super.createOverlay(overlayConfig);
  }

  private buildOverlayConfig(
    tooltipConfig: CustomOverlayConfig<TooltipOverlayData>,
    connectedTo?: FlexibleConnectedPositionStrategyOrigin,
  ): OverlayConfig {
    const positionStrategy: FlexibleConnectedPositionStrategy | GlobalPositionStrategy = connectedTo
      ? this.overlay.position().flexibleConnectedTo(connectedTo).withPositions(this.TOOLTIP_PREFERRED_POSITIONS)
      : this.overlay.position().global().top();

    return {
      panelClass: TOOLTIP_CONTAINER_CLASS,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ autoClose: true }),
      positionStrategy,

      ...tooltipConfig.overlayConfig,
    };
  }
}
