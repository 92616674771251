<mat-form-field appearance="fill">
  <mat-label>{{ fromLabel() }}</mat-label>
  <input
    matInput
    [matDatepicker]="startDatePicker"
    [(ngModel)]="startDate"
    (dateChange)="onDateChange()"
    [min]="minDate()"
    [max]="endDate()"
  />
  <mat-datepicker-toggle matSuffix [for]="startDatePicker" />
  <mat-datepicker #startDatePicker />
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>{{ toLabel() }}</mat-label>
  <input
    matInput
    [matDatepicker]="endDatePicker"
    [(ngModel)]="endDate"
    (dateChange)="onDateChange()"
    [min]="startDate() ?? minDate()"
  />
  <mat-datepicker-toggle matSuffix [for]="endDatePicker" />
  <mat-datepicker #endDatePicker />
</mat-form-field>
