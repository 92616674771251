import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mp-entity-item-status',
  standalone: true,
  template: '',
  styleUrl: './entity-item-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityItemStatusComponent {
  @HostBinding('class') readonly class = 'mp-entity-item-status';
}
