import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';

import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'mp-nav-profile',
  standalone: true,
  templateUrl: './navigation-profile.component.html',
  styleUrl: './navigation-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, MatLegacyButtonModule, MatIconModule, MatLegacyMenuModule, AvatarComponent],
})
export class NavigationProfileComponent {
  readonly user: InputSignal<string | null | undefined> = input<string | null>();
  readonly organization: InputSignal<string | null | undefined> = input<string | null>();
  readonly image: InputSignal<string | null | undefined> = input<string | null>();

  readonly size: InputSignal<'small' | 'large'> = input<'small' | 'large'>('large');

  readonly logout = output<void>();

  @HostBinding('class')
  protected get className() {
    return `mp-nav-profile mp-nav-profile--${this.size()}`;
  }

  protected handleLogout(): void {
    this.logout.emit();
  }
}
