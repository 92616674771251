import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mp-header-nav',
  standalone: true,
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class HeaderNavComponent {}
