import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { ListResultsInfoComponent } from './list-results-info/list-results-info.component';

@Component({
  selector: 'mp-list-header',
  standalone: true,
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, ListResultsInfoComponent],
})
export class ListHeaderComponent {
  @HostBinding() readonly class = 'mp-list-header';

  readonly headerValue: InputSignal<string | undefined> = input<string | undefined>();
  readonly headerPlaceholder: InputSignal<string> = input<string>('');
  readonly showListHeaderSearchLabel: InputSignal<boolean> = input<boolean>(false);
  readonly listHeaderSearchLabel: InputSignal<string> = input<string>('Suche nach');
  readonly selectedItemsQuantity: InputSignal<number> = input<number>(0);
  readonly totalItemsQuantity: InputSignal<number> = input<number>(0);
}
