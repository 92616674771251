import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

export interface ItemSelectorOption<T = unknown> {
  label: string;
  value: T;
}

@Component({
  selector: 'mp-chip-item-selector',
  standalone: true,
  templateUrl: './chip-item-selector.component.html',
  styleUrls: ['./chip-item-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, MatButtonModule, MatChipsModule, MatIconModule, MatMenuModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipItemSelectorComponent),
      multi: true,
    },
  ],
})
export class ChipItemSelectorComponent<T = unknown> implements ControlValueAccessor {
  @Input() label = '';

  @Input() set allOptions(allOptions: ItemSelectorOption<T>[]) {
    this._allOptions = allOptions;
    this.handleSelectedAndAvailableOptions();
  }

  @Input() set selectedItems(selectedItems: T[]) {
    this._selectedItems = selectedItems;
    this.handleSelectedAndAvailableOptions();
  }

  @Output() readonly selectedItemsChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  availableOptions: ItemSelectorOption<T>[] = [];

  selectedOptions: ItemSelectorOption<T>[] = [];

  private _allOptions: ItemSelectorOption<T>[] = [];

  private _selectedItems: T[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  selectItem(value: T): void {
    const selectedItems: T[] = [...this._selectedItems, value];
    this.writeValue(selectedItems);
    this.onTouched();
    this.selectedItemsChange.emit(this._selectedItems);
  }

  removeSelectedItem(value: T): void {
    const selectedItems: T[] = this._selectedItems.filter((item) => item !== value);
    this.writeValue(selectedItems);
    this.onTouched();
    this.selectedItemsChange.emit(this._selectedItems);
  }

  trackByFn(index: number): number {
    return index;
  }

  private handleSelectedAndAvailableOptions() {
    this.availableOptions = this._allOptions.filter(({ value }) => !this._selectedItems.includes(value));

    this.selectedOptions = this._selectedItems
      .map((item) => this._allOptions.find(({ value }) => value === item))
      .filter((option): option is ItemSelectorOption<T> => !!option);
  }

  writeValue(selectedItems: T[]): void {
    this._selectedItems = selectedItems || [];
    this.handleSelectedAndAvailableOptions();

    this.onChange(this._selectedItems);

    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  onChange = (value: T[]) => {};

  onTouched = () => {};

  registerOnChange(onChange: (value: T[]) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
}
