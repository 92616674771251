import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@Component({
  selector: 'mp-inline-edit',
  standalone: true,
  templateUrl: './inline-edit.component.html',
  styleUrl: './inline-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgTemplateOutlet, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule],
})
export class InlineEditComponent {
  @HostBinding('class') readonly class = 'mp-inline-edit';

  @Input() value = '';

  @Input() inputPlaceholder = 'Name';

  @Input() minLength = 1;

  @Input() maxLength = 50;

  @Output() readonly valueSubmit: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly inputFieldVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('inlineInput') inlineInput!: ElementRef<HTMLInputElement>;

  inputFieldVisible = false;

  constructor(private cdr: ChangeDetectorRef) {}

  showInputField(): void {
    this.cdr.markForCheck();

    this.inputFieldVisible = true;
    this.inputFieldVisibilityChange.emit(true);

    requestAnimationFrame(() => {
      this.inlineInput?.nativeElement.focus();
      this.inlineInput?.nativeElement.select();
    });
  }

  hideInputField(): void {
    this.inputFieldVisible = false;
    this.inputFieldVisibilityChange.emit(false);
  }

  cancelEdit(): void {
    this.hideInputField();
    this.cancel.emit();
  }

  onValueSubmit(value?: string): void {
    if (!value) return;

    this.valueSubmit.emit(value);
    this.hideInputField();
  }
}
