import { NgModule } from '@angular/core';

import { EntityItemSlotsModule } from './entity-item-slots/entity-item-slots.module';
import { EntityItemComponent } from './entity-item/entity-item.component';
import { EntityListGroupComponent } from './entity-list-group/entity-list-group.component';
import { EntityListHeadComponent } from './entity-list-head/entity-list-head.component';
import { EntityListComponent } from './entity-list/entity-list.component';

@NgModule({
  imports: [
    EntityItemComponent,
    EntityListComponent,
    EntityListHeadComponent,
    EntityListGroupComponent,
    EntityItemSlotsModule,
  ],
  exports: [
    EntityItemComponent,
    EntityListComponent,
    EntityListHeadComponent,
    EntityListGroupComponent,
    EntityItemSlotsModule,
  ],
})
export class EntityItemsModule {}
