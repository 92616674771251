import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, delay, filter, pairwise } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AngularPluginRouter {
  // Delay the events stream to ensure that the page title is updated before the page view is tracked
  readonly routerEvents$ = this.router.events.pipe(delay(0));

  readonly navigationEnd$ = this.routerEvents$.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
  );

  private readonly previousUrl$: Observable<string> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    pairwise(),
    map(([prev]) => prev.urlAfterRedirects),
  );

  readonly previousUrl: Signal<string | undefined> = toSignal(this.previousUrl$, { initialValue: undefined });

  constructor(private readonly router: Router) {}

  get url(): string {
    return this.router.url;
  }

  get events(): Observable<unknown> {
    return this.routerEvents$;
  }
}
