@if (image(); as image) {
  <img class="avatar" [class]="avatarSizeCssClassObject()" [src]="image" (error)="handleImageLoadError()" />
} @else if (initials()) {
  <div class="avatar" [class]="avatarSizeCssClassObject()">
    {{ initials() }}
  </div>
} @else {
  <img class="avatar" [class]="avatarSizeCssClassObject()" [src]="placeholderImageUrl" />
}

@if (upload()) {
  <button mat-button color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>add_photo_alternate</mat-icon>
    BILD ÄNDERN
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="fileInput.click()">Bild auswählen</button>

    @if (image()) {
      <button mat-menu-item (click)="clearImage()">Bild entfernen</button>
    }
  </mat-menu>

  <input #fileInput hidden type="file" [accept]="acceptedFormats" (change)="onFileChange($event)" />
}
