import { NgModule } from '@angular/core';

import { SelectorItemComponent } from './component/item/selector-item.component';
import { SelectorComponent } from './component/selector.component';

@NgModule({
  imports: [SelectorComponent, SelectorItemComponent],
  exports: [SelectorComponent, SelectorItemComponent],
})
export class SelectorFlyoutModule {}
