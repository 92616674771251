import { NgModule } from '@angular/core';

import { DragHandleComponent } from './drag-handle/drag-handle.component';
import {
  EntityItemActionsSlotComponent,
  EntityItemContentLabelSlotComponent,
  EntityItemContentMetaSlotComponent,
  EntityItemContentSlotComponent,
  EntityItemFooterSlotComponent,
  EntityItemIconSlotComponent,
} from './entity-item-slots';
import { EntityItemSlotsComponent } from './entity-item-slots.component';
import { EntityItemStatusComponent } from './entity-status/entity-item-status.component';

@NgModule({
  imports: [
    EntityItemSlotsComponent,

    EntityItemIconSlotComponent,
    EntityItemContentSlotComponent,
    EntityItemContentLabelSlotComponent,
    EntityItemContentMetaSlotComponent,
    EntityItemActionsSlotComponent,
    EntityItemStatusComponent,
    EntityItemFooterSlotComponent,

    DragHandleComponent,
  ],
  exports: [
    EntityItemSlotsComponent,

    EntityItemIconSlotComponent,
    EntityItemContentSlotComponent,
    EntityItemContentLabelSlotComponent,
    EntityItemContentMetaSlotComponent,
    EntityItemActionsSlotComponent,
    EntityItemStatusComponent,
    EntityItemFooterSlotComponent,

    DragHandleComponent,
  ],
})
export class EntityItemSlotsModule {}
