import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SelectorItemComponent } from '../../../flyout/selector/component/item/selector-item.component';

@Component({
  selector: 'mp-selected-list-item',
  standalone: true,
  templateUrl: './selected-list-item.component.html',
  styleUrl: './selected-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectorItemComponent, MatIconModule],
})
export class SelectedListItemComponent {
  readonly disabled: InputSignal<boolean> = input<boolean>(false);

  readonly itemClick: OutputEmitterRef<void> = output<void>();

  onClick(): void {
    this.itemClick.emit();
  }
}
