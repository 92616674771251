import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OutputEmitterRef,
  Signal,
  WritableSignal,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { LetDirective } from '@ngrx/component';

import { CustomChipsComponent, RemoveChip } from '../custom-chips';

import { ActiveFilters } from './active-filters';

@Component({
  selector: 'mp-active-filters',
  standalone: true,
  templateUrl: './active-filters.component.html',
  styleUrl: './active-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    KeyValuePipe,

    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,

    CustomChipsComponent,
  ],
})
export class ActiveFiltersComponent {
  readonly activeFilters: InputSignal<ActiveFilters[]> = input<ActiveFilters[]>([]);

  readonly remove: OutputEmitterRef<RemoveChip> = output<RemoveChip>();

  protected readonly collapsed: WritableSignal<boolean> = signal<boolean>(false);

  protected readonly selectedFiltersCount: Signal<number> = computed(() => this.calculateSelectedFiltersCount());

  collapseFilter(): void {
    this.collapsed.set(!this.collapsed());
  }

  private calculateSelectedFiltersCount(): number {
    let count = 0;

    for (const filter of this.activeFilters()) {
      count += filter.properties.length;
    }

    return count;
  }

  removeFilter(removedChip: RemoveChip): void {
    this.remove.emit(removedChip);
  }
}
