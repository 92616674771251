import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PageLink } from '@mp/shared/data-access';

import { HeaderNavElementComponent } from '../header-nav-element/header-nav-element.component';
import { HeaderNavComponent } from '../header-nav/header-nav.component';

@Component({
  selector: 'mp-root-module-nav',
  standalone: true,
  templateUrl: './root-module-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, HeaderNavComponent, HeaderNavElementComponent],
})
export class RootModuleNavComponent {
  @HostBinding('class') readonly class = 'hidden lg:inline-block';

  readonly label: InputSignal<string> = input.required<string>();

  readonly rootModuleLink: InputSignal<PageLink> = input<PageLink>(['./']);

  readonly exact: InputSignal<boolean> = input<boolean>(false);

  readonly activated: OutputEmitterRef<void> = output<void>();
}
