@if (!isProcessing) {
  <button
    class="export-button"
    mat-raised-button
    [disabled]="disabled"
    [matTooltip]="disabled ? disabledStateTooltipText : ''"
    (click)="emitButtonClicked()"
  >
    <mat-icon>login</mat-icon>
    Export
  </button>
} @else {
  <button
    mat-flat-button
    class="export-in-progress-button"
    color="accent"
    [disabled]="true"
    title="Wird verarbeitet..."
  >
    <mat-progress-spinner class="export-in-progress-button__spinner" mode="indeterminate" diameter="20" />
  </button>
}
