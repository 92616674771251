<div class="button-content-row">
  <div class="content-col">
    <button class="main-button" mat-button color="accent" [disabled]="buttonDisabled()" (click)="emitButtonClick($event)">
      <ng-content />
    </button>
  </div>

  @if (hasDropdown) {
    <div class="dropdown-col">
      <button
        mat-button
        class="dropdown-button"
        color="accent"
        [disabled]="dropdownDisabled()"
        [matMenuTriggerFor]="dropdown"
        (click)="emitDropdownClick($event)"
      >
        <mat-icon class="button-icon">arrow_drop_down</mat-icon>
      </button>
    </div>
  }
</div>

<mat-menu #dropdown="matMenu" xPosition="before" yPosition="below">
  <ng-content select="[mat-menu-item]" />
</mat-menu>
