<div class="title-row">
  <div class="title-col-count">
    @if (!(count() === 0 && hideZero())) {
      <span>{{ count() }}</span>
    }
  </div>
  <div class="title-col-label">
    <h5><ng-content /></h5>
  </div>
</div>
