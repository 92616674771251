import { SelectionModel } from '@angular/cdk/collections';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  TemplateRef,
  effect,
  input,
  model,
  output,
  untracked,
} from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { TemplateContext } from '@core/shared/util';

import { SelectorItemComponent } from '../../../flyout/selector/component/item/selector-item.component';
import { SelectOption } from '../../../option';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { IdFn } from '../../models';
import { valueAsId } from '../../utils';
import { SelectorBaseComponent } from '../selector-base/selector-base.component';

@Component({
  selector: 'mp-list-item-selector',
  standalone: true,
  templateUrl: './list-item-selector.component.html',
  styleUrl: './list-item-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, NgTemplateOutlet, SelectorItemComponent, SelectorBaseComponent, SpinnerComponent],
})
export class ListItemSelectorComponent<T> {
  readonly selectedValues: ModelSignal<T[]> = model<T[]>([]);

  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly isLoading: InputSignal<boolean> = input<boolean>(false);

  readonly idFn: InputSignal<IdFn<T>> = input<IdFn<T>>(valueAsId);

  readonly itemsDeselactable: InputSignal<boolean> = input<boolean>(true);

  readonly noOptionsLabel: InputSignal<string> = input<string>('');

  readonly multipleSelection: InputSignal<boolean> = input<boolean>(false);

  readonly optionTemplate: InputSignal<TemplateRef<TemplateContext<T>> | undefined> =
    input<TemplateRef<TemplateContext<T>>>();

  readonly searched: OutputEmitterRef<string> = output<string>();

  readonly optionTemplateContextType!: TemplateContext<T>;

  protected readonly selectionModel: SelectionModel<T> = new SelectionModel<T>(
    true,
    [],
    true,
    (o1, o2) => this.idFn()(o1) === this.idFn()(o2),
  );

  constructor(private cdr: ChangeDetectorRef) {
    effect(() => {
      const selectedValues: T[] = this.selectedValues();

      untracked(() => {
        this.selectionModel.setSelection(...selectedValues);
        this.cdr.markForCheck();
      });
    });
  }

  onSearch(searchTerm: string): void {
    this.searched.emit(searchTerm);
  }

  onOptionToggle(value: T): void {
    if (this.multipleSelection()) {
      this.selectionModel.toggle(value);
    } else {
      this.selectionModel.setSelection(value);
    }

    this.selectedValues.set(this.selectionModel.selected);
  }
}
