<div class="drag">
  <div class="drag-handle">
    <!-- You need to project your own icon here,
         so that you can attach the [cdkDragHandle] directive: -->
    <ng-content />
  </div>

  @if (showIndexInput) {
    <div class="drag-index">
      <form [formGroup]="indexInputControl" novalidate>
        <mat-form-field appearance="fill">
          <input matInput type="number" [formControl]="indexInputControl" [min]="minIndex" [max]="maxIndex" />
        </mat-form-field>
      </form>
    </div>
  }
</div>
