<button
  class="copy-to-clipboard-button"
  mat-icon-button
  [disabled]="isDisabled()"
  matTooltip="In die Zwischenablage kopieren"
  matTooltipPosition="above"
  [matTooltipDisabled]="isDisabled()"
  (click)="$event.stopPropagation(); copyTextToClipboard()"
>
  <mat-icon
    *ngrxLet="textCopied$ as textCopied"
    class="copy-to-clipboard-button__icon"
    [class]="{ 'copy-to-clipboard-button__icon--text-copied': textCopied }"
  >
    {{ textCopied ? 'done' : 'content_copy' }}
  </mat-icon>
</button>
