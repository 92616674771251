<table
  class="graduation-table"
  mat-table
  [dataSource]="graduation().levels || [] | slice: 0 : visibleGraduationLevelsLimit()"
  [trackBy]="trackByFn"
>
  @for (graduationName of graduation().graduationNames; track index; let index = $index) {
    <ng-container [matColumnDef]="getGraduationColumnName(index)">
      <mat-header-cell *matHeaderCellDef class="graduation-table__header-cell" [title]="graduationName">
        <span class="truncate">{{ graduationName }}</span>
      </mat-header-cell>

      <mat-cell *matCellDef="let level" class="graduation-table__cell">
        <ng-container *ngrxLet="getGraduationLevelRequirement(level, index) as requirement">
          @if (requirement) {
            <span
              *ngrxLet="requirement | mpGraduationValueOutput as requirementLabel"
              class="graduation-requirement-value truncate"
              [title]="'ab ' + requirementLabel"
            >
              <span class="graduation-requirement-value__prefix">{{ index > 0 ? 'und ab ' : 'ab ' }}</span>
              {{ requirementLabel }}
            </span>
          }
        </ng-container>
      </mat-cell>
    </ng-container>
  }

  <ng-container [matColumnDef]="GRADUATION_EFFECT_COLUMN_NAME">
    <mat-header-cell *matHeaderCellDef class="graduation-table__header-cell" />
    <mat-cell *matCellDef="let level" class="graduation-table__cell">
      <mp-graduation-effect
        class="graduation-effect"
        [graduationLevel]="level"
        [isFutureCondition]="isFutureCondition()"
      />
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="DESCRIPTION_COLUMN_NAME">
    <mat-header-cell class="graduation-table__header-cell graduation-table__header-cell-description" *matHeaderCellDef>
      <span class="truncate">Beschreibung</span>
    </mat-header-cell>

    <mat-cell class="graduation-table__cell graduation-table__cell-description" *matCellDef="let level">
      @if (level.description; as description) {
        <div class="level-description" [innerHTML]="description"></div>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="graduation-label">
    <mat-footer-cell class="graduation-labels" *matFooterCellDef>
      @for (label of graduation().labels | keyvalue; track $index) {
        <div class="graduation-label">
          <span class="graduation-label-key">{{ label.key }}: </span>
          <span class="graduation-label-value">{{ label.value }}</span>
        </div>
      }
    </mat-footer-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns()"
    class="graduation-table__header"
    [ngClass]="{ 'graduation-table__header--first': isFirst() }"
  />

  <mat-row
    *matRowDef="let level; columns: displayedColumns()"
    class="graduation-table__row"
    [ngClass]="{
      'graduation-table__row--signed-current': level.isSignedCondition && !isFutureCondition(),
      'graduation-table__row--signed-future': level.isSignedCondition && isFutureCondition()
    }"
  />

  @if (graduation().labels) {
    <mat-footer-row *matFooterRowDef="['graduation-label']" />
  }
</table>

@if (hasMore()) {
  <mp-graduation-expand-button [(isExpanded)]="isExpanded" [hiddenElementsCount]="hiddenElementsCount()" />
}
