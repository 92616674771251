import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, TemplateRef, input } from '@angular/core';

@Component({
  selector: 'mp-param-wrapper',
  standalone: true,
  templateUrl: './param-wrapper.component.html',
  styleUrls: ['./param-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgTemplateOutlet],
})
export class ParamWrapperComponent {
  readonly label: InputSignal<string> = input<string>('');

  readonly boldLabel: InputSignal<boolean> = input<boolean>(false);

  /**
   * Optional template for more complex labels. If provided, the simple label input will be ignored.
   */
  readonly labelTemplate: InputSignal<TemplateRef<unknown> | null> = input<TemplateRef<unknown> | null>(null);
}
