import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IconDirective } from '@core/shared/util';

import { FilterInfo } from '../filter-info';

@Component({
  selector: 'mp-collapsed-filters',
  standalone: true,
  templateUrl: './collapsed-filters.component.html',
  styleUrls: ['./collapsed-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgFor, MatIconModule, MatTooltipModule, IconDirective],
})
export class CollapsedFiltersComponent {
  @Input() filtersSummary: FilterInfo[] = [];

  trackByFn(index: number): number {
    return index;
  }
}
