<div class="card" [class.add-item]="addItem()" [class.removed]="removed()">
  <ng-content />
</div>

@if (!addItem()) {
  <div class="interaction">
    @if (!removed()) {
      <button mat-icon-button color="accent" aria-label="Remove Subentity" (click)="remove.emit()">
        <mat-icon>close</mat-icon>
      </button>
    } @else {
      <button mat-icon-button color="accent" aria-label="Revert Remove Subentity" (click)="reverse.emit()">
        <mat-icon>undo</mat-icon>
      </button>
    }
  </div>
}
