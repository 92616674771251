import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';

import { FilterItem, FilterItemConfig } from '../filter-item';

export class FilterItemManager<T> {
  private readonly _items$ = new BehaviorSubject([] as Array<FilterItem<T>>);
  readonly items$: Observable<Array<FilterItem<T>>>;
  readonly selectedItems$: Observable<Array<FilterItem<T>>>;

  private readonly _selection$ = new BehaviorSubject([] as Array<number | string>);
  readonly valueChanges$: Observable<Array<T>>;

  private readonly _multiple$: BehaviorSubject<boolean>;
  readonly multiple$: Observable<boolean>;

  private selectionModel: SelectionModel<number | string>;
  private changeSubscription: Subscription;

  constructor(multipleSelection = true, initiallySelected = []) {
    this._multiple$ = new BehaviorSubject(multipleSelection);
    this.multiple$ = this._multiple$.pipe(distinctUntilChanged());

    this.items$ = combineLatest([this._items$, this._selection$]).pipe(
      map(([items, selection]) => {
        /* Set "selected" property on items$ */
        items.forEach((item) => (item.selected = selection.includes(item.key)));
        return items;
      }),
    );

    this.selectedItems$ = this.items$.pipe(
      map((items) => items.filter((item) => item.selected)),
      distinctUntilChanged((before, after) => this.itemsAreEqual(before, after)),
    );

    this.valueChanges$ = this.selectedItems$.pipe(
      skip(1),
      map((items) => items.map((item) => item.value)),
    );

    this.selectionModel = new SelectionModel<number | string>(multipleSelection, initiallySelected);
    this.changeSubscription = this.selectionModel.changed.subscribe(() => {
      this._selection$.next(this.selectionModel.selected);
    });
  }

  private itemsAreEqual(x: Array<FilterItem<T>>, y: Array<FilterItem<T>>): boolean {
    if (x.length !== y.length) {
      return false;
    }
    for (let i = 0; i < x.length; i++) {
      if (x[i].key !== y[i].key) {
        return false;
      }
    }

    return true;
  }

  private migrateSelectionModel(newItems: Array<FilterItemConfig<T>>): void {
    const initiallySelected = newItems.filter((item) => item.selected).map((item) => item.key);

    const currentlySelected = this.selectionModel.selected.filter((key) => newItems.some((item) => key === item.key));

    const newInitialSelection = [...currentlySelected, ...initiallySelected];

    this.rebuildSelectionModel(this._multiple$.getValue(), newInitialSelection);
  }

  private rebuildSelectionModel(isMulti: boolean, selected: Array<number | string>): void {
    const selection = isMulti ? selected : selected.slice(-1);
    this.selectionModel = new SelectionModel<number | string>(isMulti, selection);

    this.changeSubscription.unsubscribe();
    this.changeSubscription = this.selectionModel.changed.subscribe({
      next: () => this._selection$.next(this.selectionModel.selected),
    });

    this._selection$.next(selection);
  }

  setMultipleSelection(multiple: boolean): void {
    this._multiple$.next(multiple);
    this.rebuildSelectionModel(multiple, this.selectionModel.selected);
  }

  setItems(items: Array<FilterItem<T>>): void {
    this.migrateSelectionModel(items);
    this._items$.next(items);
  }

  select(...keys: Array<number | string>): void {
    this.selectionModel.select(...keys);
  }

  selectAll(): void {
    if (!this._multiple$.getValue()) {
      throw new Error('"selectAll()" can ONLY be used with mulitselect active!');
    }

    const notSelected = this._items$
      .getValue()
      .filter((item) => !item.selected)
      .map((item) => item.key);

    this.select(...notSelected);
  }

  deselectAll(): void {
    if (!this._multiple$.getValue()) {
      throw new Error('"deselectAll()" can ONLY be used with mulitselect active!');
    }

    const selected = this._items$
      .getValue()
      .filter((item) => item.selected)
      .map((item) => item.key);

    this.deselect(...selected);
  }

  deselect(...keys: Array<number | string>): void {
    this.selectionModel.deselect(...keys);
  }

  dispose(): void {
    this.changeSubscription?.unsubscribe();
  }
}
